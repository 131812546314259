/*
Author         : G-axon
Template Name  : Wieldy Antd React - Admin Template
Version        : 1.0
*/
/*=============================================================
    Table of Contents:
    =============================
    A. Globals
        01. Custom Animation
        02. Colors Class

    B. Base
        01. Base Styles
        02. Row Col Styles
        03. Margin Padding Styles
        04. Typography Styles
        05. Flex Styles

    C. Layouts
        01. Ant Layout Style
        02. Header Style
        03. Sidebar Style
        04. Navbar(Ant Menu) Style
        05. Footer Styles
        06. Customizer Style
        07. Drawer Style

    D. UI Components
        01. Alert Styles
        02. Avatar Styles
        03. BackTop Styles
        04. Badges Styles
        05. Button Styles
        06. Cards Styles
        07. Cascader Styles
        08. Chart Styles
        09. Form Styles
        10. Icon Styles
        11. Input Styles
        12. List Styles
        13. Loader Styles
        14. Pagination Styles
        15. Pickers Styles
        16. Progress Styles
        17. Slider Styles
        18. Steps Styles
        19. Tables Styles
        20. Tabs Styles
        21. Time Lines Style

    E. Apps
        01. Apps Style
        02. Mails Apps Styles
        03. Chat Apps Styles
        04. Contact Apps Styles
        05. Calendar Apps Style
        06. Profile App Style
        07. Wall App Style

    F. Pages
        01. Callout Styles
        02. E-commerce Styles
        03. Pricing Tables Styles
        04. Login Styles
        05. Dashboard Styles
        06. Error Styles
        07. Editor Styles
        08. Testimonial Styles
        09. Algolia Styles

  =============================================================*/
/*Typography Styles*/
h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  color: #262626;
  font-weight: 400;
  line-height: 1.2;
}
h1,
.h1 {
  font-size: 22px;
}
.framed-layout h1,
.framed-layout .h1 {
  font-size: 20px;
}
@media screen and (max-width: 767px) {
  .framed-layout h1,
  .framed-layout .h1 {
    font-size: 18px;
  }
}
.boxed-layout h1,
.boxed-layout .h1 {
  font-size: 18px;
}
@media screen and (max-width: 767px) {
  .boxed-layout h1,
  .boxed-layout .h1 {
    font-size: 16px;
  }
}
@media screen and (min-width: 1600px) {
  h1,
  .h1 {
    font-size: 28px;
  }
  .boxed-layout h1,
  .boxed-layout .h1 {
    font-size: 18px;
  }
}
h2,
.h2 {
  font-size: 20px;
}
h3,
.h3 {
  font-size: 18px;
}
h4,
.h4 {
  font-size: 16px;
}
h5,
.h5 {
  font-size: 14px;
}
h6,
.h6 {
  font-size: 12px;
}
.gx-h1-lg {
  font-size: 28px;
}
.gx-text-strikethrough {
  text-decoration: line-through !important;
}
.gx-text-underline {
  text-decoration: underline !important;
}
/*Font size Classes*/
/*8px*/
.gx-fs-xxs {
  font-size: 8px;
}
/*10px*/
.gx-fs-xs {
  font-size: 10px;
}
/*11px*/
.gx-fs-11 {
  font-size: 11px;
}
/*12px*/
.gx-fs-sm {
  font-size: 12px;
  line-height: 1.5;
}
/*14px*/
.gx-fs-md {
  font-size: 14px;
  line-height: 1;
}
/*16px*/
.gx-fs-lg {
  font-size: 16px;
  line-height: 1;
}
/*20px*/
.gx-fs-xl {
  font-size: 20px;
  line-height: 1;
}
/*24px*/
.gx-fs-xxl {
  font-size: 24px;
  line-height: 1.3;
}
@media screen and (max-width: 1199px) {
  .gx-fs-xxl {
    font-size: 20px;
  }
}
/*28px*/
.gx-fs-2xl {
  font-size: 28px !important;
  line-height: 1;
}
@media screen and (max-width: 1199px) {
  .gx-fs-2xl {
    font-size: 20px !important;
  }
}
/*30px*/
.gx-fs-xxxl {
  font-size: 30px;
  line-height: 1;
}
@media screen and (max-width: 1366px) {
  .gx-fs-xxxl {
    font-size: 28px;
  }
}
@media screen and (max-width: 1199px) {
  .gx-fs-xxxl {
    font-size: 26px;
  }
}
@media screen and (max-width: 991px) {
  .gx-fs-xxxl {
    font-size: 24px;
  }
}
/*40px*/
.gx-fs-xlxl {
  font-size: 40px;
  line-height: 1;
}
@media screen and (max-width: 1199px) {
  .gx-fs-xlxl {
    font-size: 32px;
  }
}
@media screen and (max-width: 991px) {
  .gx-fs-xlxl {
    font-size: 28px;
  }
}
/*50*/
.gx-fs-icon-lg {
  font-size: 50px;
  line-height: 1;
}
@media screen and (max-width: 1366px) {
  .gx-fs-icon-lg {
    font-size: 38px;
  }
}
@media screen and (max-width: 575px) {
  .gx-fs-icon-lg {
    font-size: 30px;
  }
}
/*54px*/
.gx-fs-iconcard {
  font-size: 54px;
  line-height: 1;
}
@media screen and (max-width: 1366px) {
  .gx-fs-iconcard {
    font-size: 34px;
  }
}
.gx-text-lowercase {
  text-transform: lowercase;
}
.gx-text-uppercase {
  text-transform: uppercase;
}
.gx-text-capitalize {
  text-transform: capitalize;
}
.gx-font-weight-light {
  font-weight: 300;
}
.gx-font-weight-normal {
  font-weight: 400;
}
.gx-font-weight-medium {
  font-weight: 500;
}
.gx-font-weight-semi-bold {
  font-weight: 600;
}
.gx-font-weight-bold {
  font-weight: 700;
}
.gx-font-weight-heavy {
  font-weight: 900;
}
.gx-font-italic {
  font-style: italic;
}
.gx-letter-spacing-base {
  letter-spacing: 3px;
}
.gx-letter-spacing-lg {
  letter-spacing: 6px;
}
.gx-letter-spacing-xl {
  letter-spacing: 8px;
}
.gx-text-justify {
  text-align: justify !important;
}
.gx-text-nowrap {
  white-space: nowrap !important;
}
.gx-text-left {
  text-align: left !important;
}
.gx-text-right {
  text-align: right !important;
}
.gx-text-center {
  text-align: center !important;
}
.gx-text-truncate {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
/* Ant Layout Style */
.ant-layout {
  position: relative;
  background: none;
}
.ant-layout > .gx-layout-content {
  overflow-x: hidden;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
}
.full-scroll .ant-layout > .gx-layout-content {
  overflow-x: inherit;
}
.ant-layout.ant-layout-has-sider > .ant-layout {
  position: relative;
  background: #ffffff;
}
.framed-layout .ant-layout,
.boxed-layout .ant-layout {
  background: #ffffff;
}
.framed-layout .ant-layout,
.boxed-layout .ant-layout {
  background: #ffffff;
}
.ant-layout.gx-app-layout {
  height: 100vh;
}
.framed-layout .ant-layout.gx-app-layout {
  height: calc(100vh - 2 * 20px);
}
@media screen and (max-width: 991px) {
  .framed-layout .ant-layout.gx-app-layout {
    height: 100vh;
  }
}
.framed-layout.full-scroll .ant-layout.gx-app-layout,
.boxed-layout.full-scroll .ant-layout.gx-app-layout {
  overflow-y: scroll;
}
.ant-layout.gx-app-layout > .ant-layout {
  height: 100vh;
}
.framed-layout .ant-layout.gx-app-layout > .ant-layout {
  height: calc(100vh - 2 * 20px);
}
@media screen and (max-width: 991px) {
  .framed-layout .ant-layout.gx-app-layout > .ant-layout {
    height: 100vh;
  }
}
.full-scroll .ant-layout.ant-layout-has-sider > .ant-layout,
.full-scroll .ant-layout.ant-layout-has-sider > .ant-layout-content {
  overflow-x: inherit;
}
.gx-main-content-wrapper {
  padding: 32px 32px 0;
  flex: 1;
}
@media screen and (max-width: 575px) {
  .gx-main-content-wrapper {
    padding: 20px 15px 0;
  }
}
.gx-container-wrap .gx-main-content-wrapper {
  width: 94vw;
  margin-left: auto;
  margin-right: auto;
  padding-left: 16px;
  padding-right: 16px;
}
@media screen and (min-width: 768px) {
  .gx-container-wrap .gx-main-content-wrapper {
    width: 720px;
  }
}
@media screen and (min-width: 992px) {
  .gx-container-wrap .gx-main-content-wrapper {
    width: 920px;
  }
}
@media screen and (min-width: 1200px) {
  .gx-container-wrap .gx-main-content-wrapper {
    width: 1100px;
  }
}
@media screen and (min-width: 1367px) {
  .gx-container-wrap .gx-main-content-wrapper {
    width: 1200px;
  }
}
@media screen and (min-width: 1600px) {
  .gx-container-wrap .gx-main-content-wrapper {
    width: 1400px;
  }
}
.gx-main-content {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  flex: 1;
  height: 100%;
}
.gx-container {
  width: 94vw;
  margin-left: auto;
  margin-right: auto;
  padding-left: 16px;
  padding-right: 16px;
}
@media screen and (min-width: 768px) {
  .gx-container {
    width: 720px;
  }
}
@media screen and (min-width: 992px) {
  .gx-container {
    width: 920px;
  }
}
@media screen and (min-width: 1200px) {
  .gx-container {
    width: 1100px;
  }
}
@media screen and (min-width: 1367px) {
  .gx-container {
    width: 1200px;
  }
}
@media screen and (min-width: 1600px) {
  .gx-container {
    width: 1400px;
  }
}
/* Header Style */
.ant-layout-header {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-align-items: center;
  -ms-align-items: center;
  align-items: center;
  -webkit-justify-content: space-between;
  -ms-justify-content: space-between;
  justify-content: space-between;
  line-height: 1;
  padding: 0 32px;
  height: 56px;
  position: relative;
  z-index: 10;
  color: #262626;
  border-bottom: 1px solid #dde1ee;
  background-color: #fefefe;
}
.ant-layout-header .header-left {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 50%;
}
.ant-layout-header .header-right {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  width: 50%;
}
.ant-layout-header .ant-menu-horizontal .ant-menu-item {
  border-bottom: none;
}
.ant-layout-header .ant-menu-horizontal .ant-menu-item-selected {
  border-bottom: none;
}
.ant-layout-header .ant-menu-horizontal .ant-menu-item:hover {
  border-bottom: none;
}
@media screen and (max-width: 991px) {
  .ant-layout-header {
    padding: 0 15px;
  }
}
@media screen and (max-width: 575px) {
  .ant-layout-header {
    height: auto;
  }
}
.gx-nav-header {
  padding: 0 32px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
  background-color: #fefefe;
}
.gx-nav-header .ant-menu-horizontal {
  margin-bottom: -1px;
  border-bottom-color: transparent;
}
.gx-nav-header-below {
  -webkit-box-shadow: 0 1px 2px 1px rgba(0, 0, 0, 0.28);
  -moz-box-shadow: 0 1px 2px 1px rgba(0, 0, 0, 0.28);
  box-shadow: 0 1px 2px 1px rgba(0, 0, 0, 0.28);
  position: relative;
  z-index: 999;
}
.ant-dropdown-menu-item {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-align-items: center;
  -ms-align-items: center;
  align-items: center;
}
.gx-linebar {
  font-size: 20px;
}
.gx-header-horizontal-dark .gx-linebar {
  color: #ffffff;
}
.gx-header-notifications {
  list-style: none;
  margin: 0;
  padding-left: 0;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-align-items: center;
  -ms-align-items: center;
  align-items: center;
}
.gx-header-notifications > li {
  font-size: 18px;
}
.gx-header-notifications > li:not(:last-child) {
  margin-right: 42px;
}
.gx-inside-header-horizontal .gx-header-notifications > li:not(:last-child) {
  margin-right: 20px;
}
.gx-header-horizontal-main .gx-header-notifications > li:not(:last-child),
.gx-header-horizontal-nav .gx-header-notifications > li:not(:last-child) {
  margin-right: 30px;
}
@media screen and (max-width: 991px) {
  .gx-header-horizontal-main .gx-header-notifications > li:not(:last-child),
  .gx-header-horizontal-nav .gx-header-notifications > li:not(:last-child) {
    margin-right: 16px;
  }
}
@media screen and (max-width: 991px) {
  .gx-header-notifications > li:not(:last-child) {
    margin-right: 16px;
  }
}
.gx-header-notifications > li.gx-notify {
  margin-right: 22px;
}
.gx-header-horizontal-main .gx-header-notifications > li.gx-notify {
  margin-right: 30px;
}
@media screen and (max-width: 991px) {
  .gx-header-horizontal-main .gx-header-notifications > li.gx-notify {
    margin-right: 16px;
  }
}
.gx-inside-header-horizontal .gx-header-notifications > li.gx-notify {
  margin-right: 20px;
}
@media screen and (max-width: 991px) {
  .gx-header-notifications > li.gx-notify {
    margin-right: 16px;
  }
}
.gx-header-notifications > li.gx-language {
  font-size: 14px;
}
@media screen and (max-width: 575px) {
  .gx-header-notifications > li.gx-language .gx-language-name,
  .gx-header-notifications > li.gx-language .icon {
    display: none;
  }
  .gx-header-notifications > li.gx-language .flag.flag-24 {
    width: 20px;
    height: 20px;
    margin-top: -4px;
  }
}
@media screen and (max-width: 575px) {
  .gx-header-notifications > li.gx-notify,
  .gx-header-notifications > li.gx-msg {
    margin-top: 2px;
  }
  .gx-header-notifications > li.gx-language {
    margin-bottom: 2px;
  }
}
.gx-header-horizontal-main .gx-header-notifications > li.gx-user-nav {
  margin-left: 50px;
}
@media screen and (max-width: 991px) {
  .gx-header-horizontal-main .gx-header-notifications > li.gx-user-nav {
    margin-left: 0;
  }
}
@media screen and (max-width: 1199px) {
  .gx-inside-header-horizontal .gx-header-horizontal-main .gx-header-notifications > li.gx-user-nav {
    margin-left: 0;
  }
}
.gx-header-horizontal-main .gx-header-notifications li > span {
  color: #585e75;
}
.gx-header-horizontal-dark .gx-header-horizontal-main .gx-header-notifications li > span {
  color: #dde1ee;
}
.gx-popover-scroll {
  height: 280px !important;
  width: 300px !important;
  margin: 0 -16px;
}
@media screen and (max-width: 575px) {
  .gx-popover-scroll {
    height: 280px !important;
    width: 200px !important;
  }
}
.gx-popover-lang-scroll {
  height: 220px !important;
  width: 160px !important;
  margin: 0 -16px;
}
.ant-popover-placement-bottomRight > .ant-popover-content > .ant-popover-arrow {
  right: 10px;
}
.gx-popover-header {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-align-items: center;
  -ms-align-items: center;
  align-items: center;
  -webkit-justify-content: space-between;
  -ms-justify-content: space-between;
  justify-content: space-between;
  border-bottom: solid 1px #e8e8e8;
  margin: 0 -16px;
  padding: 0 16px 12px;
}
.gx-sub-popover {
  list-style: none;
  margin: 0 16px;
  padding-left: 0;
}
.gx-sub-popover li {
  padding: 20px 0 14px;
}
.gx-popover-lang-scroll .gx-sub-popover li {
  padding: 5px 0;
}
.gx-sub-popover li:not(:last-child) {
  border-bottom: solid 1px #e8e8e8;
}
.gx-user-popover {
  margin: -12px -16px;
  padding: 7px 0;
  list-style: none;
}
.gx-user-popover li {
  cursor: pointer;
  padding: 3px 15px;
  width: 150px;
}
.gx-user-popover li:hover,
.gx-user-popover li:focus {
  background-color: #fafafa;
}
@media screen and (max-width: 575px) {
  .gx-user-popover li {
    width: 100%;
  }
}
.gx-header-horizontal {
  position: relative;
  color: #dde1ee;
  background-color: #ffffff;
}
.gx-header-horizontal-dark {
  position: relative;
  background-color: #003366;
  color: #dde1ee;
}
.gx-header-horizontal-dark:before {
  content: '';
  position: absolute;
  right: 169px;
  top: -212px;
  background-color: rgba(255, 255, 255, 0.2);
  width: 280px;
  height: 350px;
  -webkit-border-radius: 0 0 135px 135px;
  -moz-border-radius: 0 0 135px 135px;
  border-radius: 0 0 135px 135px;
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
  z-index: 1;
}
@media screen and (max-width: 1049px) {
  .gx-header-horizontal-dark:before {
    right: 110px;
  }
}
@media screen and (max-width: 1199px) {
  .gx-header-horizontal-dark:before {
    top: -184px;
    right: 80px;
    height: 300px;
  }
}
@media screen and (max-width: 991px) {
  .gx-header-horizontal-dark:before {
    top: -154px;
    right: 140px;
    height: 240px;
    width: 200px;
  }
}
@media screen and (max-width: 575px) {
  .gx-header-horizontal-dark:before {
    right: 60px;
    display: none;
  }
}
.gx-header-horizontal-dark > div {
  position: relative;
  z-index: 2;
}
.gx-header-horizontal-top {
  padding: 12px 0;
  font-size: 14px;
}
.gx-header-horizontal-top-flex,
.gx-header-horizontal-nav-flex,
.gx-header-horizontal-main-flex {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-align-items: center;
  -ms-align-items: center;
  align-items: center;
}
.gx-header-horizontal-top-left {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  margin-right: 15px;
}
@media screen and (max-width: 767px) {
  .gx-header-horizontal-top-left {
    margin-right: 0;
    width: 100%;
  }
}
.gx-header-horizontal-main {
  display: -webkit-block;
  display: -ms-blockbox;
  display: -ms-block;
  display: block;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  padding: 25px 0 35px;
  height: auto;
  background-color: transparent;
}
@media screen and (max-width: 1199px) {
  .gx-header-horizontal-main {
    padding: 20px 0;
  }
  .gx-header-horizontal-main .gx-w-logo {
    padding: 7px 5px;
  }
}
@media screen and (max-width: 991px) {
  .gx-header-horizontal-main {
    padding: 0;
  }
}
@media screen and (max-width: 575px) {
  .gx-header-horizontal-main .gx-w-logo {
    padding-right: 0;
  }
}
.gx-inside-header-horizontal .gx-header-horizontal-main {
  padding: 0;
}
.gx-header-horizontal-nav {
  background-color: #003366;
  color: #fffffd;
}
.gx-header-horizontal-dark .gx-header-horizontal-nav {
  background-color: transparent;
}
.gx-below-header-horizontal .gx-header-horizontal-nav,
.gx-above-header-horizontal .gx-header-horizontal-nav {
  background-color: #fefefe;
  color: #7d839d;
}
.gx-inside-header-horizontal .gx-logo {
  margin-right: 25px;
}
.gx-inside-header-horizontal:before {
  top: -230px;
}
@media screen and (max-width: 1199px) {
  .gx-inside-header-horizontal:before {
    top: -190px;
  }
}
@media screen and (max-width: 991px) {
  .gx-inside-header-horizontal:before {
    top: -154px;
  }
}
.gx-above-header-horizontal:before {
  top: -165px;
}
@media screen and (max-width: 991px) {
  .gx-above-header-horizontal:before {
    top: -154px;
  }
}
.gx-login-list {
  list-style: none;
  margin-left: auto;
  margin-bottom: 0;
  padding-left: 0;
}
.gx-login-list li {
  padding: 0 10px;
  text-transform: uppercase;
  color: #dde1ee;
  font-size: 12px;
  line-height: 12px;
  display: inline-block;
  cursor: pointer;
}
.gx-login-list li:not(:first-child) {
  border-left: solid 1px #e8e8e8;
}
.gx-header-horizontal-dark .gx-login-list li:not(:first-child) {
  border: 0 none;
}
@media screen and (max-width: 767px) {
  .gx-login-list {
    display: none;
  }
}
.gx-header-search {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  margin-left: 28px;
}
.gx-header-search .ant-select-selection,
.gx-header-search .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  -webkit-border-radius: 0 4px 4px 0;
  -moz-border-radius: 0 4px 4px 0;
  border-radius: 0 4px 4px 0;
  background-color: #003366;
  border-color: #003366;
  color: #fffffd;
  margin-bottom: 0;
}
.gx-header-search .ant-select-selection:focus,
.gx-header-search .ant-select-single:not(.ant-select-customize-input) .ant-select-selector:focus {
  background-color: #003366;
  border-color: #003366;
  color: #fffffd;
}
.gx-header-horizontal-dark .gx-header-search .ant-select-selection,
.gx-header-horizontal-dark .gx-header-search .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  color: #fffffd;
  background-color: rgba(255, 255, 255, 0.2);
  border-color: rgba(255, 255, 255, 0.2);
  margin-left: 1px;
}
.gx-header-horizontal-dark .gx-header-search .ant-select-selection:focus,
.gx-header-horizontal-dark .gx-header-search .ant-select-single:not(.ant-select-customize-input) .ant-select-selector:focus {
  color: #fffffd;
  background-color: rgba(255, 255, 255, 0.2);
  border-color: rgba(255, 255, 255, 0.2);
}
.gx-header-search .ant-select-arrow {
  color: #fffffd;
}
.gx-popover-horizantal .ant-btn {
  margin: 0 !important;
  border: 0 none;
  border-right: solid 1px #e8e8e8;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  height: 24px;
  line-height: 24px;
}
.gx-popover-horizantal .gx-popover-search-bar {
  margin: 0;
}
.gx-popover-horizantal .gx-popover-search-bar .ant-input {
  height: 24px;
  line-height: 24px;
}
@media screen and (max-width: 575px) {
  .gx-popover-horizantal {
    left: 0 !important;
    padding-top: 0;
    margin-top: -9px;
  }
  .gx-popover-horizantal .ant-popover-inner-content {
    width: 100vw;
  }
  .gx-popover-horizantal .ant-popover-arrow {
    display: none;
  }
  .gx-popover-horizantal .gx-popover-search-bar {
    width: 100%;
  }
  .gx-popover-horizantal .gx-popover-scroll,
  .gx-popover-horizantal .gx-popover-lang-scroll {
    width: 100% !important;
    margin: 0;
  }
  .gx-popover-horizantal .gx-sub-popover {
    margin: 0;
    padding-left: 5px;
  }
}
.gx-no-header-horizontal {
  padding: 14px 32px 13px 79px;
  text-align: center;
  position: relative;
}
.gx-no-header-horizontal-top {
  padding: 10px 20px;
  background-color: #d9f7be;
  color: #237804;
  border-radius: 4px;
  display: inline-block;
}
@media screen and (max-width: 575px) {
  .gx-no-header-horizontal-top {
    width: 100%;
    font-size: 12px;
  }
}
.gx-no-header-horizontal-top-center {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
}
.gx-no-header-horizontal .gx-linebar {
  position: absolute;
  left: 32px;
  top: 15px;
  z-index: 1;
}
@media screen and (max-width: 575px) {
  .gx-no-header-horizontal .gx-linebar {
    left: 15px;
  }
}
@media screen and (max-width: 575px) {
  .gx-no-header-horizontal {
    padding: 14px 15px 13px 60px;
  }
}
/* Sidebar Style */
.gx-app-sidebar {
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  -ms-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
  transition: all 0.2s ease;
}
.ant-layout-sider {
  background-color: #fffffc;
  color: #585e75;
  flex: 0 0 auto !important;
  max-width: none !important;
  min-width: 10px !important;
  width: 280px !important;
  overflow: hidden;
}
@media screen and (max-width: 1366px) {
  .ant-layout-sider {
    width: 240px !important;
  }
}
.ant-layout-sider-collapsed {
  width: 80px !important;
}
@media screen and (max-width: 991px) {
  .ant-layout-sider-collapsed.gx-mini-custom-sidebar {
    width: 0 !important;
    min-width: 0 !important;
  }
}
.ant-layout-sider.gx-collapsed-sidebar {
  width: 0 !important;
  min-width: 0 !important;
}
@media screen and (max-width: 991px) {
  .ant-layout-sider {
    width: 0 !important;
    min-width: 0 !important;
  }
}
.gx-layout-sider-dark {
  background-color: #003366;
  color: #038fdd;
}
.gx-layout-sider-header {
  padding: 10px 30px 10px 70px;
  height: 56px;
  -webkit-box-shadow: 0 0 4px 4px rgba(0, 0, 0, 0.08);
  -moz-box-shadow: 0 0 4px 4px rgba(0, 0, 0, 0.08);
  box-shadow: 0 0 4px 4px rgba(0, 0, 0, 0.08);
  position: relative;
  z-index: 1;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-align-items: center;
  -ms-align-items: center;
  align-items: center;
}
@media screen and (max-width: 575px) {
  .gx-layout-sider-header {
    height: 50px;
  }
}
.gx-layout-sider-header .gx-site-logo {
  display: block;
}
.ant-layout-sider-collapsed .gx-layout-sider-header .gx-site-logo {
  display: none;
}
.gx-mini-custom-sidebar .gx-layout-sider-header .gx-site-logo {
  display: block;
}
.gx-layout-sider-header .gx-linebar {
  position: absolute;
  left: 20px;
  z-index: 1;
  top: 15px;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}
.ant-layout-sider-collapsed .gx-layout-sider-header .gx-linebar {
  left: 20px;
}
@media screen and (max-width: 991px) {
  .gx-layout-sider-header .gx-linebar {
    display: none;
  }
}
.ant-layout-sider-collapsed .gx-layout-sider-header {
  padding-left: 20px;
  padding-right: 20px;
}
.gx-drawer-sidebar .gx-layout-sider-header {
  padding-left: 30px;
}
.gx-drawer-sidebar-dark .gx-layout-sider-header {
  background-color: rgba(0, 0, 0, 0.3);
}
.gx-layout-sider-dark .gx-layout-sider-header {
  background-color: rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}
.gx-mini-custom-sidebar .gx-layout-sider-header {
  -webkit-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
  background-color: transparent;
}
.gx-custom-sidebar .gx-layout-sider-header {
  padding-left: 30px;
  background-color: transparent;
}
.gx-mini-custom-sidebar.ant-layout-sider-lite .gx-layout-sider-header,
.gx-custom-sidebar.ant-layout-sider-lite .gx-layout-sider-header {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  border-right: 1px solid #e8e8e8;
}
@media screen and (max-width: 991px) {
  .gx-layout-sider-header {
    padding-left: 30px;
  }
}
.gx-sidebar-content {
  border-right: 1px solid #e8e8e8;
}
.gx-layout-sider-dark .gx-sidebar-content,
.gx-drawer-sidebar-dark .gx-sidebar-content {
  border-right: 0 none;
}
.gx-layout-sider-scrollbar {
  height: calc(100vh - 56px - 145px) !important;
}
.framed-layout .gx-layout-sider-scrollbar {
  height: calc(100vh - 56px - 2 * 20px - 145px) !important;
}
@media screen and (max-width: 991px) {
  .framed-layout .gx-layout-sider-scrollbar {
    height: calc(100vh - 56px) !important;
  }
}
.ant-layout-sider-collapsed .gx-layout-sider-scrollbar {
  height: calc(100vh - 56px - 91px) !important;
}
.framed-layout .ant-layout-sider-collapsed .gx-layout-sider-scrollbar {
  height: calc(100vh - 56px - 2 * 20px - 91px) !important;
}
@media screen and (max-width: 991px) {
  .framed-layout .ant-layout-sider-collapsed .gx-layout-sider-scrollbar {
    height: calc(100vh - 56px) !important;
  }
}
@media screen and (max-width: 991px) {
  .ant-layout-sider-collapsed .gx-layout-sider-scrollbar {
    height: calc(100vh - 56px) !important;
  }
}
@media screen and (max-width: 575px) {
  .ant-layout-sider-collapsed .gx-layout-sider-scrollbar {
    height: calc(100vh - 50px) !important;
  }
}
.gx-mini-custom-sidebar .gx-layout-sider-scrollbar {
  height: calc(100vh - 56px - 206px) !important;
}
.framed-layout .gx-mini-custom-sidebar .gx-layout-sider-scrollbar {
  height: calc(100vh - 56px - 2 * 20px - 206px) !important;
}
@media screen and (max-width: 991px) {
  .framed-layout .gx-mini-custom-sidebar .gx-layout-sider-scrollbar {
    height: calc(100vh - 56px) !important;
  }
}
@media screen and (max-width: 991px) {
  .gx-layout-sider-scrollbar {
    height: calc(100vh - 56px) !important;
  }
}
@media screen and (max-width: 575px) {
  .gx-layout-sider-scrollbar {
    height: calc(100vh - 50px) !important;
  }
}
@media screen and (max-width: 991px) {
  .gx-no-header-notifications + .gx-layout-sider-scrollbar {
    height: calc(100vh - 56px - 145px) !important;
  }
}
@media screen and (max-width: 575px) {
  .gx-no-header-notifications + .gx-layout-sider-scrollbar {
    height: calc(100vh - 50px - 145px) !important;
  }
}
.gx-sidebar-notifications {
  padding: 30px 10px 10px;
  margin: 0 20px 10px;
  border-bottom: solid 1px #e8e8e8;
}
.ant-layout-sider-dark .gx-sidebar-notifications,
.gx-drawer-sidebar-dark .gx-sidebar-notifications {
  border-bottom-color: #038fdd;
}
@media screen and (max-width: 991px) {
  .gx-sidebar-notifications {
    display: none;
  }
}
@media screen and (max-width: 991px) {
  .gx-sidebar-notifications.gx-no-header-notifications {
    display: block;
  }
}
.gx-app-nav {
  list-style: none;
  padding-left: 0;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-align-items: center;
  -ms-align-items: center;
  align-items: center;
  margin: 0 -20px;
  color: #007aff;
}
.ant-layout-sider-dark .gx-app-nav,
.gx-drawer-sidebar-dark .gx-app-nav {
  color: #038fdd;
}
.gx-app-nav li {
  padding: 0 20px;
  font-size: 20px;
}
.gx-app-nav li .icon {
  cursor: pointer;
}
.ant-layout-sider-collapsed .gx-app-nav {
  display: none;
}
.gx-mini-custom-sidebar .gx-app-nav {
  display: block;
  margin-top: 15px;
}
.gx-mini-custom-sidebar .gx-app-nav li:not(:last-child) {
  margin-bottom: 5px;
}
.gx-avatar-name {
  cursor: pointer;
}
.ant-layout-sider-collapsed .gx-avatar-name {
  display: none;
}
.ant-layout-sider-collapsed .gx-avatar-row {
  margin-bottom: 0 !important;
}
.gx-avatar-row .ant-avatar {
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
  position: relative;
  left: 0;
}
.ant-layout-sider-collapsed .gx-avatar-row .ant-avatar {
  margin: 0 !important;
  left: -10px;
}
.gx-menu-group .ant-menu-item-group-title {
  font-size: 16px;
  padding-top: 30px !important;
}
.gx-layouts-view .gx-pointer {
  display: block;
  margin-bottom: 15px;
}
.gx-layouts-view .gx-pointer img {
  width: 100%;
}
.gx-layouts-view .gx-pointer.active {
  box-shadow: 0 0 10px 0 #007aff;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px;
}
/* Ant Menu Style */
.ant-menu {
  color: #585e75;
}
.ant-menu-horizontal {
  background: none;
}
.ant-menu-item-group-title {
  color: #545454;
  font-size: 11px;
  line-height: 1.3;
  padding: 10px 30px;
}
.ant-menu-submenu-popup .ant-menu-item-group-title {
  padding-left: 20px;
}
.ant-layout-sider .ant-menu-item-group-title {
  padding-top: 40px;
}
.ant-menu-item:active,
.ant-menu-submenu-title:active {
  background: #007aff;
}
.ant-menu-item .icon {
  min-width: 14px;
  margin-right: 20px;
  display: inline-block;
  vertical-align: middle;
}
.ant-menu-item .icon[class^='icon-']::before,
.ant-menu-item .icon[class*=' icon-']::before {
  position: relative;
  top: 2px;
}
.ant-menu-sub .ant-menu-item .icon {
  font-size: 12px;
}
.ant-menu-item > a {
  color: #585e75;
}
.ant-menu-item > a:hover {
  color: #007aff;
}
.ant-menu-item-divider {
  background-color: #e8e8e8;
}
.ant-menu-submenu-inline {
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}
.ant-menu-item:hover,
.ant-menu-item-active,
.ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open,
.ant-menu-submenu-active,
.ant-menu-submenu-title:hover {
  color: #007aff;
}
.ant-menu-horizontal > .ant-menu-item:hover,
.ant-menu-horizontal > .ant-menu-item-active,
.ant-menu-horizontal > .ant-menu-submenu .ant-menu-submenu-title:hover {
  background-color: transparent;
}
.ant-menu-item-selected {
  color: #007aff;
}
.ant-menu-item-selected > a {
  color: #585e75;
}
.ant-menu-item-selected > a:hover {
  color: #007aff;
}
.ant-menu-inline,
.ant-menu-vertical,
.ant-menu-vertical-left {
  border-right: 1px solid #e8e8e8;
}
.ant-layout-sider .ant-menu-inline,
.ant-layout-sider .ant-menu-vertical,
.ant-layout-sider .ant-menu-vertical-left,
.gx-drawer-sidebar .ant-menu-inline,
.gx-drawer-sidebar .ant-menu-vertical,
.gx-drawer-sidebar .ant-menu-vertical-left {
  border-right: 0 none;
}
.ant-menu-vertical-right {
  border-left: 1px solid #e8e8e8;
}
.ant-menu-item,
.ant-menu-submenu-title {
  padding: 0 20px;
}
.gx-inside-header-horizontal .ant-menu-item,
.gx-inside-header-horizontal .ant-menu-submenu-title {
  padding: 0 10px;
}
@media screen and (max-width: 1199px) {
  .gx-inside-header-horizontal .ant-menu-item,
  .gx-inside-header-horizontal .ant-menu-submenu-title {
    padding: 0 6px;
  }
}
.ant-menu > .ant-menu-item-divider {
  background-color: #e8e8e8;
}
.ant-menu-sub > li > a {
  position: relative;
}
.ant-menu-submenu > .ant-menu {
  background-color: #007aff;
  border-radius: 0;
}
.ant-menu-submenu > .ant-menu.ant-menu-sub .ant-menu-item {
  padding-left: 54px !important;
}
.ant-layout-sider .ant-menu-submenu > .ant-menu.ant-menu-sub .ant-menu-item,
.ant-drawer .ant-menu-submenu > .ant-menu.ant-menu-sub .ant-menu-item {
  padding-left: 80px !important;
}
.ant-layout-sider .ant-menu-submenu > .ant-menu.ant-menu-sub .ant-menu-sub .ant-menu-item,
.ant-drawer .ant-menu-submenu > .ant-menu.ant-menu-sub .ant-menu-sub .ant-menu-item {
  padding-left: 106px !important;
}
.ant-menu-submenu-popup {
  border-radius: 0;
}
.ant-menu-submenu-popup .ant-menu-sub.ant-menu .ant-menu-item {
  padding-left: 20px !important;
}
.gx-app-sidebar .ant-menu-submenu-vertical > .ant-menu-submenu-title .ant-menu-submenu-arrow,
.gx-app-sidebar .ant-menu-submenu-vertical-left > .ant-menu-submenu-title .ant-menu-submenu-arrow,
.gx-app-sidebar .ant-menu-submenu-vertical-right > .ant-menu-submenu-title .ant-menu-submenu-arrow,
.gx-app-sidebar .ant-menu-submenu-inline > .ant-menu-submenu-title .ant-menu-submenu-arrow {
  left: 22px;
  right: auto;
}
.ant-drawer .ant-menu-submenu-vertical > .ant-menu-submenu-title .ant-menu-submenu-arrow,
.ant-drawer .ant-menu-submenu-vertical-left > .ant-menu-submenu-title .ant-menu-submenu-arrow,
.ant-drawer .ant-menu-submenu-vertical-right > .ant-menu-submenu-title .ant-menu-submenu-arrow,
.ant-drawer .ant-menu-submenu-inline > .ant-menu-submenu-title .ant-menu-submenu-arrow {
  left: 22px;
  right: auto;
}
.ant-menu-submenu-vertical > .ant-menu-submenu-title .ant-menu-submenu-arrow:before,
.ant-menu-submenu-vertical-left > .ant-menu-submenu-title .ant-menu-submenu-arrow:before,
.ant-menu-submenu-vertical-right > .ant-menu-submenu-title .ant-menu-submenu-arrow:before,
.ant-menu-submenu-inline > .ant-menu-submenu-title .ant-menu-submenu-arrow:before,
.ant-menu-submenu-vertical > .ant-menu-submenu-title .ant-menu-submenu-arrow:after,
.ant-menu-submenu-vertical-left > .ant-menu-submenu-title .ant-menu-submenu-arrow:after,
.ant-menu-submenu-vertical-right > .ant-menu-submenu-title .ant-menu-submenu-arrow:after,
.ant-menu-submenu-inline > .ant-menu-submenu-title .ant-menu-submenu-arrow:after {
  background-image: linear-gradient(to right, #585e75, #585e75);
}
.gx-app-sidebar .ant-menu-submenu-vertical .ant-menu-submenu-inline .ant-menu-submenu-title .ant-menu-submenu-arrow,
.gx-app-sidebar .ant-menu-submenu-vertical-left .ant-menu-submenu-inline .ant-menu-submenu-title .ant-menu-submenu-arrow,
.gx-app-sidebar .ant-menu-submenu-vertical-right .ant-menu-submenu-inline .ant-menu-submenu-title .ant-menu-submenu-arrow,
.gx-app-sidebar .ant-menu-submenu-inline .ant-menu-submenu-inline .ant-menu-submenu-title .ant-menu-submenu-arrow,
.ant-drawer .ant-menu-submenu-vertical .ant-menu-submenu-inline .ant-menu-submenu-title .ant-menu-submenu-arrow,
.ant-drawer .ant-menu-submenu-vertical-left .ant-menu-submenu-inline .ant-menu-submenu-title .ant-menu-submenu-arrow,
.ant-drawer .ant-menu-submenu-vertical-right .ant-menu-submenu-inline .ant-menu-submenu-title .ant-menu-submenu-arrow,
.ant-drawer .ant-menu-submenu-inline .ant-menu-submenu-inline .ant-menu-submenu-title .ant-menu-submenu-arrow {
  left: 60px;
}
.ant-menu-submenu-vertical > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow:after,
.ant-menu-submenu-vertical-left > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow:after,
.ant-menu-submenu-vertical-right > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow:after,
.ant-menu-submenu-inline > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow:after,
.ant-menu-submenu-vertical > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow:before,
.ant-menu-submenu-vertical-left > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow:before,
.ant-menu-submenu-vertical-right > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow:before,
.ant-menu-submenu-inline > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow:before {
  background: linear-gradient(to right, #007aff, #007aff);
}
.ant-menu-vertical .ant-menu-submenu-selected,
.ant-menu-vertical-left .ant-menu-submenu-selected,
.ant-menu-vertical-right .ant-menu-submenu-selected {
  color: #007aff;
}
.ant-menu-vertical .ant-menu-submenu-selected > a,
.ant-menu-vertical-left .ant-menu-submenu-selected > a,
.ant-menu-vertical-right .ant-menu-submenu-selected > a {
  color: #007aff;
}
.ant-menu-horizontal {
  border-bottom: 1px solid #e8e8e8;
  line-height: 46px;
  padding: 0;
}
.ant-layout-header .ant-menu-horizontal {
  border-bottom: 0 none;
  line-height: 54px;
}
.ant-menu-horizontal > .ant-menu-item,
.ant-menu-horizontal > .ant-menu-submenu {
  border-bottom: 2px solid transparent;
}
.ant-menu-horizontal > .ant-menu-item:hover,
.ant-menu-horizontal > .ant-menu-submenu:hover,
.ant-menu-horizontal > .ant-menu-item-active,
.ant-menu-horizontal > .ant-menu-submenu-active,
.ant-menu-horizontal > .ant-menu-item-open,
.ant-menu-horizontal > .ant-menu-submenu-open,
.ant-menu-horizontal > .ant-menu-item-selected,
.ant-menu-horizontal > .ant-menu-submenu-selected {
  border-bottom: 2px solid #007aff;
  color: #007aff;
}
.ant-menu-horizontal > .ant-menu-item > a,
.ant-menu-horizontal > .ant-menu-submenu > a {
  color: #585e75;
}
.ant-menu-horizontal > .ant-menu-item > a:hover,
.ant-menu-horizontal > .ant-menu-submenu > a:hover {
  color: #007aff;
}
.ant-menu-horizontal > .ant-menu-item > a:before,
.ant-menu-horizontal > .ant-menu-submenu > a:before {
  bottom: -2px;
}
.ant-menu-vertical .ant-menu-item:not(:last-child),
.ant-menu-vertical-left .ant-menu-item:not(:last-child),
.ant-menu-vertical-right .ant-menu-item:not(:last-child),
.ant-menu-inline .ant-menu-item:not(:last-child) {
  margin-bottom: 0;
}
.ant-menu-vertical .ant-menu-item:after,
.ant-menu-vertical-left .ant-menu-item:after,
.ant-menu-vertical-right .ant-menu-item:after,
.ant-menu-inline .ant-menu-item:after {
  border-right: 3px solid #007aff;
}
.ant-menu-vertical .ant-menu-item,
.ant-menu-vertical-left .ant-menu-item,
.ant-menu-vertical-right .ant-menu-item,
.ant-menu-inline .ant-menu-item,
.ant-menu-vertical .ant-menu-submenu-title,
.ant-menu-vertical-left .ant-menu-submenu-title,
.ant-menu-vertical-right .ant-menu-submenu-title,
.ant-menu-inline .ant-menu-submenu-title {
  padding: 0 30px 0 20px;
  font-size: 14px;
  margin-top: 0;
  margin-bottom: 0;
  text-overflow: inherit;
}
.ant-layout-sider .ant-menu-vertical .ant-menu-item,
.ant-layout-sider .ant-menu-vertical-left .ant-menu-item,
.ant-layout-sider .ant-menu-vertical-right .ant-menu-item,
.ant-layout-sider .ant-menu-inline .ant-menu-item,
.ant-layout-sider .ant-menu-vertical .ant-menu-submenu-title,
.ant-layout-sider .ant-menu-vertical-left .ant-menu-submenu-title,
.ant-layout-sider .ant-menu-vertical-right .ant-menu-submenu-title,
.ant-layout-sider .ant-menu-inline .ant-menu-submenu-title,
.ant-drawer .ant-menu-vertical .ant-menu-item,
.ant-drawer .ant-menu-vertical-left .ant-menu-item,
.ant-drawer .ant-menu-vertical-right .ant-menu-item,
.ant-drawer .ant-menu-inline .ant-menu-item,
.ant-drawer .ant-menu-vertical .ant-menu-submenu-title,
.ant-drawer .ant-menu-vertical-left .ant-menu-submenu-title,
.ant-drawer .ant-menu-vertical-right .ant-menu-submenu-title,
.ant-drawer .ant-menu-inline .ant-menu-submenu-title {
  padding-left: 40px !important;
}
.ant-menu-vertical .ant-menu-item .icon,
.ant-menu-vertical-left .ant-menu-item .icon,
.ant-menu-vertical-right .ant-menu-item .icon,
.ant-menu-inline .ant-menu-item .icon,
.ant-menu-vertical .ant-menu-submenu-title .icon,
.ant-menu-vertical-left .ant-menu-submenu-title .icon,
.ant-menu-vertical-right .ant-menu-submenu-title .icon,
.ant-menu-inline .ant-menu-submenu-title .icon {
  min-width: 14px;
  margin-right: 20px;
}
.ant-menu-vertical .ant-menu-item .icon[class^='icon-']::before,
.ant-menu-vertical-left .ant-menu-item .icon[class^='icon-']::before,
.ant-menu-vertical-right .ant-menu-item .icon[class^='icon-']::before,
.ant-menu-inline .ant-menu-item .icon[class^='icon-']::before,
.ant-menu-vertical .ant-menu-submenu-title .icon[class^='icon-']::before,
.ant-menu-vertical-left .ant-menu-submenu-title .icon[class^='icon-']::before,
.ant-menu-vertical-right .ant-menu-submenu-title .icon[class^='icon-']::before,
.ant-menu-inline .ant-menu-submenu-title .icon[class^='icon-']::before,
.ant-menu-vertical .ant-menu-item .icon[class*=' icon-']::before,
.ant-menu-vertical-left .ant-menu-item .icon[class*=' icon-']::before,
.ant-menu-vertical-right .ant-menu-item .icon[class*=' icon-']::before,
.ant-menu-inline .ant-menu-item .icon[class*=' icon-']::before,
.ant-menu-vertical .ant-menu-submenu-title .icon[class*=' icon-']::before,
.ant-menu-vertical-left .ant-menu-submenu-title .icon[class*=' icon-']::before,
.ant-menu-vertical-right .ant-menu-submenu-title .icon[class*=' icon-']::before,
.ant-menu-inline .ant-menu-submenu-title .icon[class*=' icon-']::before {
  position: relative;
  top: 2px;
}
.ant-layout-sider-collapsed .ant-menu-vertical .ant-menu-item .icon + span,
.ant-layout-sider-collapsed .ant-menu-vertical-left .ant-menu-item .icon + span,
.ant-layout-sider-collapsed .ant-menu-vertical-right .ant-menu-item .icon + span,
.ant-layout-sider-collapsed .ant-menu-inline .ant-menu-item .icon + span,
.ant-layout-sider-collapsed .ant-menu-vertical .ant-menu-submenu-title .icon + span,
.ant-layout-sider-collapsed .ant-menu-vertical-left .ant-menu-submenu-title .icon + span,
.ant-layout-sider-collapsed .ant-menu-vertical-right .ant-menu-submenu-title .icon + span,
.ant-layout-sider-collapsed .ant-menu-inline .ant-menu-submenu-title .icon + span {
  max-width: 0;
  display: inline-block;
  opacity: 0;
}
.ant-card .ant-menu-vertical.ant-menu-sub .ant-menu-submenu-title,
.ant-card .ant-menu-vertical-left.ant-menu-sub .ant-menu-submenu-title,
.ant-card .ant-menu-vertical-right.ant-menu-sub .ant-menu-submenu-title,
.ant-card .ant-menu-inline.ant-menu-sub .ant-menu-submenu-title {
  padding-left: 54px !important;
}
.ant-layout-sider .ant-menu-vertical.ant-menu-sub .ant-menu-submenu-title,
.ant-layout-sider .ant-menu-vertical-left.ant-menu-sub .ant-menu-submenu-title,
.ant-layout-sider .ant-menu-vertical-right.ant-menu-sub .ant-menu-submenu-title,
.ant-layout-sider .ant-menu-inline.ant-menu-sub .ant-menu-submenu-title,
.ant-drawer .ant-menu-vertical.ant-menu-sub .ant-menu-submenu-title,
.ant-drawer .ant-menu-vertical-left.ant-menu-sub .ant-menu-submenu-title,
.ant-drawer .ant-menu-vertical-right.ant-menu-sub .ant-menu-submenu-title,
.ant-drawer .ant-menu-inline.ant-menu-sub .ant-menu-submenu-title {
  padding-left: 80px !important;
}
.ant-menu-inline .ant-menu-submenu-title {
  padding-right: 30px !important;
}
.ant-menu-inline-collapsed {
  width: 80px;
}
.ant-menu-inline-collapsed > .ant-menu-item,
.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item,
.ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title {
  left: 0;
  padding: 0 64px / 2 !important;
}
.ant-menu-inline-collapsed > .ant-menu-item .icon + span,
.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item .icon + span,
.ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title .icon + span {
  max-width: 0;
  display: inline-block;
  opacity: 0;
}
.ant-menu-inline-collapsed-tooltip .icon {
  display: none;
}
.ant-menu-inline-collapsed-tooltip a {
  color: rgba(255, 255, 255, 0.85);
}
.ant-menu-inline-collapsed .ant-menu-item-group-title {
  padding-left: 4px;
  padding-right: 4px;
  display: none;
}
.ant-menu-item-group-list .ant-menu-item,
.ant-menu-item-group-list .ant-menu-submenu-title {
  padding: 0 16px 0 28px;
}
.ant-menu-sub.ant-menu-inline > .ant-menu-item,
.ant-menu-sub.ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title {
  line-height: 42px;
  height: 42px;
}
.ant-menu-sub.ant-menu-inline .ant-menu-item-group-title {
  padding: 0 30px 0 80px;
  line-height: 42px;
  height: 42px;
  text-transform: uppercase;
}
.ant-menu-submenu-selected .ant-menu-submenu-title {
  color: #007aff;
}
.ant-menu-submenu-selected .ant-menu-submenu-title .ant-menu-submenu-arrow:after,
.ant-menu-submenu-selected .ant-menu-submenu-title .ant-menu-submenu-arrow:before {
  background: linear-gradient(to right, #007aff, #007aff);
}
.ant-menu-dark,
.ant-menu-dark .ant-menu-sub {
  color: #038fdd;
  background: #003366;
}
.ant-menu-dark .ant-menu-submenu-title .ant-menu-submenu-arrow:after,
.ant-menu-dark .ant-menu-sub .ant-menu-submenu-title .ant-menu-submenu-arrow:after,
.ant-menu-dark .ant-menu-submenu-title .ant-menu-submenu-arrow:before,
.ant-menu-dark .ant-menu-sub .ant-menu-submenu-title .ant-menu-submenu-arrow:before {
  background: #038fdd;
}
.ant-menu-dark .ant-menu-inline.ant-menu-sub {
  background: none;
  box-shadow: none;
}
.ant-menu-dark.ant-menu-horizontal {
  border-bottom-color: #003366;
}
.ant-menu-dark.ant-menu-horizontal > .ant-menu-item,
.ant-menu-dark.ant-menu-horizontal > .ant-menu-submenu {
  border-color: #003366;
}
.ant-menu-dark .ant-menu-item .icon {
  min-width: 14px;
  margin-right: 20px;
}
.ant-menu-dark .ant-menu-item .icon[class^='icon-']::before,
.ant-menu-dark .ant-menu-item .icon[class*=' icon-']::before {
  position: relative;
  top: 2px;
}
.ant-menu-dark .ant-menu-item-group-title {
  color: #038fdd;
}
.ant-menu-dark .ant-menu-item {
  color: #038fdd;
}
.ant-menu-dark .ant-menu-item > a {
  color: #038fdd;
}
.ant-menu-dark .ant-menu-submenu-open .ant-menu-submenu-open .ant-menu-item > a {
  color: #ffffff;
}
.ant-menu-dark .ant-menu-submenu-selected .ant-menu-submenu-selected .ant-menu-item-selected > a {
  color: #dde1ee;
}
.ant-menu-dark .ant-menu-item:hover,
.ant-menu-dark .ant-menu-item-active,
.ant-menu-dark .ant-menu-submenu-active,
.ant-menu-dark .ant-menu-submenu-selected,
.ant-menu-dark .ant-menu-submenu-title:hover,
.ant-menu-dark .ant-menu-submenu-open .ant-menu-submenu-open .ant-menu-item > a:hover {
  color: #dde1ee;
}
.ant-menu-dark .ant-menu-item:hover > a,
.ant-menu-dark .ant-menu-item-active > a,
.ant-menu-dark .ant-menu-submenu-active > a,
.ant-menu-dark .ant-menu-submenu-selected > a,
.ant-menu-dark .ant-menu-submenu-title:hover > a,
.ant-menu-dark .ant-menu-submenu-open .ant-menu-submenu-open .ant-menu-item > a:hover > a {
  color: #dde1ee;
}
.ant-menu-dark .ant-menu-item:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow:after,
.ant-menu-dark .ant-menu-item-active > .ant-menu-submenu-title > .ant-menu-submenu-arrow:after,
.ant-menu-dark .ant-menu-submenu-active > .ant-menu-submenu-title > .ant-menu-submenu-arrow:after,
.ant-menu-dark .ant-menu-submenu-selected > .ant-menu-submenu-title > .ant-menu-submenu-arrow:after,
.ant-menu-dark .ant-menu-submenu-title:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow:after,
.ant-menu-dark .ant-menu-submenu-open .ant-menu-submenu-open .ant-menu-item > a:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow:after,
.ant-menu-dark .ant-menu-item:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow:before,
.ant-menu-dark .ant-menu-item-active > .ant-menu-submenu-title > .ant-menu-submenu-arrow:before,
.ant-menu-dark .ant-menu-submenu-active > .ant-menu-submenu-title > .ant-menu-submenu-arrow:before,
.ant-menu-dark .ant-menu-submenu-selected > .ant-menu-submenu-title > .ant-menu-submenu-arrow:before,
.ant-menu-dark .ant-menu-submenu-title:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow:before,
.ant-menu-dark .ant-menu-submenu-open .ant-menu-submenu-open .ant-menu-item > a:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow:before {
  background: #ffffff;
}
.ant-menu-dark .ant-menu-item:hover > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow:after,
.ant-menu-dark .ant-menu-item-active > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow:after,
.ant-menu-dark .ant-menu-submenu-active > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow:after,
.ant-menu-dark .ant-menu-submenu-selected > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow:after,
.ant-menu-dark .ant-menu-submenu-title:hover > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow:after,
.ant-menu-dark .ant-menu-submenu-open .ant-menu-submenu-open .ant-menu-item > a:hover > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow:after,
.ant-menu-dark .ant-menu-item:hover > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow:before,
.ant-menu-dark .ant-menu-item-active > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow:before,
.ant-menu-dark .ant-menu-submenu-active > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow:before,
.ant-menu-dark .ant-menu-submenu-selected > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow:before,
.ant-menu-dark .ant-menu-submenu-title:hover > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow:before,
.ant-menu-dark .ant-menu-submenu-open .ant-menu-submenu-open .ant-menu-item > a:hover > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow:before {
  background: #dde1ee;
}
.ant-menu-dark .ant-menu-submenu-open {
  color: #ffffff;
}
.ant-menu-dark.ant-menu-inline-collapsed .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open .ant-menu-submenu-title span,
.ant-menu-dark.ant-menu-inline-collapsed .ant-menu-submenu-active .ant-menu-submenu-title span {
  color: #ffffff;
}
.ant-menu-dark .ant-menu-item-selected {
  color: #dde1ee;
}
.ant-menu-dark .ant-menu-item-selected > a,
.ant-menu-dark .ant-menu-item-selected > a:hover {
  color: #dde1ee;
}
.ant-menu-dark .ant-menu-item-selected span {
  color: inherit;
}
.ant-menu.ant-menu-dark .ant-menu-item-selected,
.ant-menu-submenu-popup.ant-menu-dark .ant-menu-item-selected {
  background-color: transparent;
}
.ant-menu-dark .ant-menu-submenu-selected .ant-menu-sub .ant-menu-submenu-title {
  color: #038fdd;
}
.ant-menu-dark .ant-menu-submenu-selected .ant-menu-sub .ant-menu-submenu-open .ant-menu-submenu-title,
.ant-menu-dark .ant-menu-submenu-selected .ant-menu-submenu-title,
.ant-menu-dark .ant-menu-submenu-selected .ant-menu-submenu-selected .ant-menu-submenu-title {
  color: #ffffff;
}
.ant-menu-dark .ant-menu-submenu-selected .ant-menu-sub .ant-menu-submenu-open .ant-menu-submenu-title:hover,
.ant-menu-dark .ant-menu-submenu-selected .ant-menu-submenu-title:hover,
.ant-menu-dark .ant-menu-submenu-selected .ant-menu-submenu-selected .ant-menu-submenu-title:hover {
  color: #dde1ee;
}
.ant-menu-submenu-horizontal > .ant-menu-submenu-title {
  color: #262626;
}
.ant-menu-dark .ant-menu-submenu-open > .ant-menu-submenu-title > .ant-menu-submenu-arrow:after,
.ant-menu-dark .ant-menu-submenu-open > .ant-menu-submenu-title > .ant-menu-submenu-arrow:before {
  background: #ffffff;
}
.ant-menu-submenu-popup.ant-menu-dark .ant-menu-submenu-vertical:hover > .ant-menu-submenu-title {
  color: #ffffff;
}
.ant-layout-sider .ant-menu .ant-menu-submenu .ant-menu-submenu-title .icon,
.ant-drawer .ant-menu .ant-menu-submenu .ant-menu-submenu-title .icon,
.ant-layout-sider .ant-menu .ant-menu-item .icon,
.ant-drawer .ant-menu .ant-menu-item .icon {
  font-size: 20px;
}
.ant-layout-sider .ant-menu .ant-menu-submenu .ant-menu-submenu .ant-menu-submenu-title .icon,
.ant-drawer .ant-menu .ant-menu-submenu .ant-menu-submenu .ant-menu-submenu-title .icon,
.ant-layout-sider .ant-menu .ant-menu-submenu .ant-menu-item .icon,
.ant-drawer .ant-menu .ant-menu-submenu .ant-menu-item .icon {
  font-size: 14px;
  margin-right: 12px;
}
.ant-layout-sider .ant-menu .ant-menu-submenu-title span,
.ant-drawer .ant-menu .ant-menu-submenu-title span {
  display: flex;
}
.ant-menu-submenu-popup.ant-menu-light .ant-menu-submenu-open > .ant-menu-submenu-title .ant-menu-submenu-arrow::before,
.ant-menu-submenu-popup.ant-menu-light .ant-menu-submenu-open > .ant-menu-submenu-title .ant-menu-submenu-arrow::after {
  background-image: linear-gradient(to right, #007aff, #007aff);
}
.ant-menu-submenu-popup.ant-menu-dark .ant-menu-item:hover > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::after,
.ant-menu-submenu-popup.ant-menu-dark .ant-menu-item-active > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::after,
.ant-menu-submenu-popup.ant-menu-dark .ant-menu-submenu-active > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::after,
.ant-menu-submenu-popup.ant-menu-dark .ant-menu-submenu-selected > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::after,
.ant-menu-submenu-popup.ant-menu-dark .ant-menu-submenu-title:hover > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::after,
.ant-menu-submenu-popup.ant-menu-dark .ant-menu-submenu-open .ant-menu-submenu-open .ant-menu-item > a:hover > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::after,
.ant-menu-submenu-popup.ant-menu-dark .ant-menu-item:hover > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::before,
.ant-menu-submenu-popup.ant-menu-dark .ant-menu-item-active > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::before,
.ant-menu-submenu-popup.ant-menu-dark .ant-menu-submenu-active > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::before,
.ant-menu-submenu-popup.ant-menu-dark .ant-menu-submenu-selected > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::before,
.ant-menu-submenu-popup.ant-menu-dark .ant-menu-submenu-title:hover > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::before,
.ant-menu-submenu-popup.ant-menu-dark .ant-menu-submenu-open .ant-menu-submenu-open .ant-menu-item > a:hover > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::before {
  background: #ffffff;
}
.gx-navbar-nav {
  list-style: none;
  padding-left: 0;
  margin: 0 -5px;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.gx-navbar-nav li {
  padding: 0 5px;
}
.gx-navbar-nav li a {
  display: block;
  padding: 4px 10px;
  background-color: #007aff;
  color: #ffffff;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}
.gx-profile-banner .gx-navbar-nav li a {
  background-color: transparent;
  color: #ffffff;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  padding: 0;
}
.gx-navbar-nav li a * {
  color: #bfbfbf;
}
.gx-navbar-nav li a:hover,
.gx-navbar-nav li a:focus {
  background-color: #0062cc;
  color: #ffffff;
}
.gx-profile-banner .gx-navbar-nav li a:hover,
.gx-profile-banner .gx-navbar-nav li a:focus {
  background-color: transparent;
  color: #dde1ee;
}
.gx-profile-banner .gx-navbar-nav li {
  padding: 0 15px;
}
@media screen and (max-width: 575px) {
  .gx-profile-banner .gx-navbar-nav li {
    padding: 0 6px;
  }
}
.gx-profile-banner .gx-navbar-nav {
  margin: 0 -15px;
}
@media screen and (max-width: 575px) {
  .gx-profile-banner .gx-navbar-nav {
    -webkit-justify-content: center;
    -ms-justify-content: center;
    justify-content: center;
    margin: 0 -6px;
  }
}
.gx-menu-horizontal {
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
}
.gx-menu-horizontal:before {
  display: none;
}
.gx-header-horizontal-nav .ant-menu-horizontal {
  border-bottom: 0 none;
}
.gx-header-horizontal-nav .ant-menu-submenu-horizontal > .ant-menu-submenu-title {
  color: #ffffff;
}
.gx-below-header-horizontal .gx-header-horizontal-nav .ant-menu-submenu-horizontal > .ant-menu-submenu-title,
.gx-above-header-horizontal .gx-header-horizontal-nav .ant-menu-submenu-horizontal > .ant-menu-submenu-title {
  color: #7d839d;
}
.gx-header-horizontal-nav .ant-menu-horizontal > .ant-menu-item:hover,
.gx-header-horizontal-nav .ant-menu-horizontal > .ant-menu-submenu:hover,
.gx-header-horizontal-nav .ant-menu-horizontal > .ant-menu-item-active,
.gx-header-horizontal-nav .ant-menu-horizontal > .ant-menu-submenu-active,
.gx-header-horizontal-nav .ant-menu-horizontal > .ant-menu-item-open,
.gx-header-horizontal-nav .ant-menu-horizontal > .ant-menu-submenu-open,
.gx-header-horizontal-nav .ant-menu-horizontal > .ant-menu-item-selected,
.gx-header-horizontal-nav .ant-menu-horizontal > .ant-menu-submenu-selected {
  border-bottom-color: #dde1ee;
  color: #dde1ee;
}
.gx-header-horizontal-nav .ant-menu-horizontal > .ant-menu-item:hover > .ant-menu-submenu-title,
.gx-header-horizontal-nav .ant-menu-horizontal > .ant-menu-submenu:hover > .ant-menu-submenu-title,
.gx-header-horizontal-nav .ant-menu-horizontal > .ant-menu-item-active > .ant-menu-submenu-title,
.gx-header-horizontal-nav .ant-menu-horizontal > .ant-menu-submenu-active > .ant-menu-submenu-title,
.gx-header-horizontal-nav .ant-menu-horizontal > .ant-menu-item-open > .ant-menu-submenu-title,
.gx-header-horizontal-nav .ant-menu-horizontal > .ant-menu-submenu-open > .ant-menu-submenu-title,
.gx-header-horizontal-nav .ant-menu-horizontal > .ant-menu-item-selected > .ant-menu-submenu-title,
.gx-header-horizontal-nav .ant-menu-horizontal > .ant-menu-submenu-selected > .ant-menu-submenu-title {
  color: #dde1ee;
}
.gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-item,
.gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-submenu {
  border-bottom: 0 none;
  margin-top: 0;
  top: 0;
}
.gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-item:hover,
.gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-submenu:hover,
.gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-item-active,
.gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-submenu-active,
.gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-item-open,
.gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-submenu-open,
.gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-item-selected,
.gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-submenu-selected {
  position: relative;
  border-bottom: 0 none;
}
.gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-item:hover > .ant-menu-submenu-title,
.gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-submenu:hover > .ant-menu-submenu-title,
.gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-item-active > .ant-menu-submenu-title,
.gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-submenu-active > .ant-menu-submenu-title,
.gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-item-open > .ant-menu-submenu-title,
.gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-submenu-open > .ant-menu-submenu-title,
.gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-item-selected > .ant-menu-submenu-title,
.gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-submenu-selected > .ant-menu-submenu-title {
  position: relative;
}
.gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-item:hover > .ant-menu-submenu-title:before,
.gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-submenu:hover > .ant-menu-submenu-title:before,
.gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-item-active > .ant-menu-submenu-title:before,
.gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-submenu-active > .ant-menu-submenu-title:before,
.gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-item-open > .ant-menu-submenu-title:before,
.gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-submenu-open > .ant-menu-submenu-title:before,
.gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-item-selected > .ant-menu-submenu-title:before,
.gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-submenu-selected > .ant-menu-submenu-title:before {
  content: '\e049';
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  text-align: center;
  font-family: 'gaxon' !important;
  font-size: 31px;
  line-height: 5px;
}
.gx-below-header-horizontal .gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-item:hover > .ant-menu-submenu-title:before,
.gx-below-header-horizontal .gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-submenu:hover > .ant-menu-submenu-title:before,
.gx-below-header-horizontal .gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-item-active > .ant-menu-submenu-title:before,
.gx-below-header-horizontal .gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-submenu-active > .ant-menu-submenu-title:before,
.gx-below-header-horizontal .gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-item-open > .ant-menu-submenu-title:before,
.gx-below-header-horizontal .gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-submenu-open > .ant-menu-submenu-title:before,
.gx-below-header-horizontal .gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-item-selected > .ant-menu-submenu-title:before,
.gx-below-header-horizontal .gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-submenu-selected > .ant-menu-submenu-title:before,
.gx-inside-header-horizontal .gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-item:hover > .ant-menu-submenu-title:before,
.gx-inside-header-horizontal .gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-submenu:hover > .ant-menu-submenu-title:before,
.gx-inside-header-horizontal .gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-item-active > .ant-menu-submenu-title:before,
.gx-inside-header-horizontal .gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-submenu-active > .ant-menu-submenu-title:before,
.gx-inside-header-horizontal .gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-item-open > .ant-menu-submenu-title:before,
.gx-inside-header-horizontal .gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-submenu-open > .ant-menu-submenu-title:before,
.gx-inside-header-horizontal .gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-item-selected > .ant-menu-submenu-title:before,
.gx-inside-header-horizontal .gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-submenu-selected > .ant-menu-submenu-title:before {
  color: #f5f5f5;
}
.gx-above-header-horizontal .gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-item:hover > .ant-menu-submenu-title:before,
.gx-above-header-horizontal .gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-submenu:hover > .ant-menu-submenu-title:before,
.gx-above-header-horizontal .gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-item-active > .ant-menu-submenu-title:before,
.gx-above-header-horizontal .gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-submenu-active > .ant-menu-submenu-title:before,
.gx-above-header-horizontal .gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-item-open > .ant-menu-submenu-title:before,
.gx-above-header-horizontal .gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-submenu-open > .ant-menu-submenu-title:before,
.gx-above-header-horizontal .gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-item-selected > .ant-menu-submenu-title:before,
.gx-above-header-horizontal .gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-submenu-selected > .ant-menu-submenu-title:before {
  color: #003366;
}
.gx-below-header-horizontal .gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-submenu:hover > .ant-menu-submenu-title:before,
.gx-below-header-horizontal .gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-item-active > .ant-menu-submenu-title:before,
.gx-below-header-horizontal .gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-submenu-active > .ant-menu-submenu-title:before,
.gx-below-header-horizontal .gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-item-open > .ant-menu-submenu-title:before,
.gx-below-header-horizontal .gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-submenu-open > .ant-menu-submenu-title:before,
.gx-above-header-horizontal .gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-submenu:hover > .ant-menu-submenu-title:before,
.gx-above-header-horizontal .gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-item-active > .ant-menu-submenu-title:before,
.gx-above-header-horizontal .gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-submenu-active > .ant-menu-submenu-title:before,
.gx-above-header-horizontal .gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-item-open > .ant-menu-submenu-title:before,
.gx-above-header-horizontal .gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-submenu-open > .ant-menu-submenu-title:before {
  color: #dde1ee;
}
.gx-inside-header-horizontal .gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-submenu:hover > .ant-menu-submenu-title:before,
.gx-inside-header-horizontal .gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-item-active > .ant-menu-submenu-title:before,
.gx-inside-header-horizontal .gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-submenu-active > .ant-menu-submenu-title:before,
.gx-inside-header-horizontal .gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-item-open > .ant-menu-submenu-title:before,
.gx-inside-header-horizontal .gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-submenu-open > .ant-menu-submenu-title:before {
  color: #ffffff;
}
.gx-submenu-popup-curve.ant-menu-submenu-popup.ant-menu-submenu > .ant-menu {
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  margin-top: -7px;
}
.gx-submenu-popup-curve.ant-menu-submenu-popup.ant-menu-submenu > .ant-menu .ant-menu-item:first-child {
  -webkit-border-radius: 10px 10px 0 0;
  -moz-border-radius: 10px 10px 0 0;
  border-radius: 10px 10px 0 0;
}
.gx-submenu-popup-curve.ant-menu-submenu-popup.ant-menu-submenu > .ant-menu .ant-menu-item:last-child {
  -webkit-border-radius: 0 0 10px 10px;
  -moz-border-radius: 0 0 10px 10px;
  border-radius: 0 0 10px 10px;
}
.gx-submenu-popup-curve.ant-menu-submenu-popup .ant-menu-submenu,
.gx-submenu-popup-curve.ant-menu-submenu-popup .ant-menu-submenu > .ant-menu {
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
}
.gx-submenu-popup-curve.ant-menu-submenu-popup .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: #dde1ee;
  color: #003366;
}
.gx-submenu-popup-curve.ant-menu-submenu-popup .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected > a {
  color: #003366;
}
.gx-submenu-popup-curve.ant-menu-submenu-popup .ant-menu-submenu-selected .ant-menu-submenu-title {
  color: #dde1ee;
}
.gx-submenu-popup-curve.ant-menu-submenu-popup .ant-menu-submenu-selected .ant-menu-submenu-title .ant-menu-submenu-arrow::after,
.gx-submenu-popup-curve.ant-menu-submenu-popup .ant-menu-submenu-selected .ant-menu-submenu-title .ant-menu-submenu-arrow::before {
  background-image: linear-gradient(to right, #dde1ee, #dde1ee);
}
.gx-submenu-popup-curve.ant-menu-submenu-popup .ant-menu-submenu-vertical > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::after,
.gx-submenu-popup-curve.ant-menu-submenu-popup .ant-menu-submenu-vertical-left > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::after,
.gx-submenu-popup-curve.ant-menu-submenu-popup .ant-menu-submenu-vertical-right > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::after,
.gx-submenu-popup-curve.ant-menu-submenu-popup .ant-menu-submenu-inline > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::after,
.gx-submenu-popup-curve.ant-menu-submenu-popup .ant-menu-submenu-open > .ant-menu-submenu-title .ant-menu-submenu-arrow::after,
.gx-submenu-popup-curve.ant-menu-submenu-popup .ant-menu-submenu-vertical > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::before,
.gx-submenu-popup-curve.ant-menu-submenu-popup .ant-menu-submenu-vertical-left > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::before,
.gx-submenu-popup-curve.ant-menu-submenu-popup .ant-menu-submenu-vertical-right > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::before,
.gx-submenu-popup-curve.ant-menu-submenu-popup .ant-menu-submenu-inline > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::before,
.gx-submenu-popup-curve.ant-menu-submenu-popup .ant-menu-submenu-open > .ant-menu-submenu-title .ant-menu-submenu-arrow::before {
  background-image: linear-gradient(to right, #dde1ee, #dde1ee);
}
.gx-submenu-popup-curve.ant-menu-submenu-popup .ant-menu-item > a:hover,
.gx-submenu-popup-curve.ant-menu-submenu-popup .ant-menu-item:hover,
.gx-submenu-popup-curve.ant-menu-submenu-popup .ant-menu-item-active,
.gx-submenu-popup-curve.ant-menu-submenu-popup .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open,
.gx-submenu-popup-curve.ant-menu-submenu-popup .ant-menu-submenu-active,
.gx-submenu-popup-curve.ant-menu-submenu-popup .ant-menu-submenu-title:hover {
  color: #dde1ee;
}
.gx-submenu-popup-curve.ant-menu-submenu-popup.gx-inside-submenu-popup-curve .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected,
.gx-submenu-popup-curve.ant-menu-submenu-popup.gx-below-submenu-popup-curve .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected,
.gx-submenu-popup-curve.ant-menu-submenu-popup.gx-above-submenu-popup-curve .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: transparent;
  color: #dde1ee;
}
.gx-submenu-popup-curve.ant-menu-submenu-popup.gx-inside-submenu-popup-curve .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected > a,
.gx-submenu-popup-curve.ant-menu-submenu-popup.gx-below-submenu-popup-curve .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected > a,
.gx-submenu-popup-curve.ant-menu-submenu-popup.gx-above-submenu-popup-curve .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected > a {
  color: #dde1ee;
}
.gx-mini-custom-sidebar .ant-menu-inline-collapsed {
  padding-bottom: 10px;
}
.gx-mini-custom-sidebar .ant-menu-inline-collapsed > .ant-menu-item,
.gx-mini-custom-sidebar .ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item,
.gx-mini-custom-sidebar .ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title {
  padding: 0 20px !important;
}
.gx-mini-custom-sidebar .ant-menu-inline-collapsed > .ant-menu-item > span,
.gx-mini-custom-sidebar .ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item > span,
.gx-mini-custom-sidebar .ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title > span {
  -webkit-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
}
.gx-mini-custom-sidebar .ant-menu-inline-collapsed > .ant-menu-item > a,
.gx-mini-custom-sidebar .ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item > a {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-align-items: center;
  -ms-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
}
.gx-mini-custom-sidebar .ant-menu-inline-collapsed > .ant-menu-item.ant-menu-item-selected > a,
.gx-mini-custom-sidebar .ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item.ant-menu-item-selected > a {
  color: #003366;
  background-color: #dde1ee;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
}
.gx-mini-custom-sidebar .ant-menu-inline-collapsed > .ant-menu-submenu.ant-menu-submenu-selected > .ant-menu-submenu-title > span {
  color: #003366;
  background-color: #dde1ee;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
}
.gx-mini-custom-sidebar .ant-menu-vertical .ant-menu-item .icon,
.gx-mini-custom-sidebar .ant-menu-vertical-left .ant-menu-item .icon,
.gx-mini-custom-sidebar .ant-menu-vertical-right .ant-menu-item .icon,
.gx-mini-custom-sidebar .ant-menu-inline .ant-menu-item .icon,
.gx-mini-custom-sidebar .ant-menu-vertical .ant-menu-submenu-title .icon,
.gx-mini-custom-sidebar .ant-menu-vertical-left .ant-menu-submenu-title .icon,
.gx-mini-custom-sidebar .ant-menu-vertical-right .ant-menu-submenu-title .icon,
.gx-mini-custom-sidebar .ant-menu-inline .ant-menu-submenu-title .icon {
  margin-right: 0;
}
.gx-no-header-submenu-popup .ant-menu-sub {
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}
.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: transparent;
}
/* Footer Style */
.ant-layout-footer {
  border-top: solid 1px #e8e8e8;
}
@media screen and (max-width: 575px) {
  .ant-layout-footer {
    padding: 14px 15px;
  }
}
.gx-container-wrap .ant-layout-footer {
  padding-left: 0;
  padding-right: 0;
}
.gx-container-wrap .gx-layout-footer-content {
  width: 94vw;
  margin-left: auto;
  margin-right: auto;
  padding-left: 16px;
  padding-right: 16px;
}
@media screen and (min-width: 768px) {
  .gx-container-wrap .gx-layout-footer-content {
    width: 720px;
  }
}
@media screen and (min-width: 992px) {
  .gx-container-wrap .gx-layout-footer-content {
    width: 920px;
  }
}
@media screen and (min-width: 1200px) {
  .gx-container-wrap .gx-layout-footer-content {
    width: 1100px;
  }
}
@media screen and (min-width: 1367px) {
  .gx-container-wrap .gx-layout-footer-content {
    width: 1200px;
  }
}
@media screen and (min-width: 1600px) {
  .gx-container-wrap .gx-layout-footer-content {
    width: 1400px;
  }
}
/* Customizer Style */
.gx-customizer {
  height: calc(100vh - 30px) !important;
}
.gx-customizer-item:not(:last-child) {
  border-bottom: solid 1px #e8e8e8;
  padding-bottom: 20px;
  margin-bottom: 20px;
}
.gx-customizer-item .ant-radio-group .ant-radio-button {
  display: none;
}
.gx-customizer-option {
  position: absolute;
  right: 0;
  top: 150px;
  z-index: 99;
}
.full-scroll .gx-customizer-option {
  position: fixed;
}
.full-scroll.boxed-layout .gx-customizer-option {
  position: absolute;
}
.full-scroll.framed-layout .gx-customizer-option {
  right: 20px;
}
@media screen and (max-width: 991px) {
  .full-scroll.framed-layout .gx-customizer-option {
    right: 0;
  }
}
.horizontal-layout.full-scroll .gx-customizer-option {
  top: 200px;
}
@media screen and (max-width: 575px) {
  .gx-customizer {
    width: 250px !important;
  }
  .gx-customizer .ant-radio-button-wrapper {
    padding: 0 10px;
  }
}
.gx-customizer .gx-list-inline {
  margin-left: 0 !important;
  margin-right: 0 !important;
}
.framed-layout .gx-customizer {
  height: calc(100vh - 30px - 2 * 20px) !important;
}
@media screen and (max-width: 991px) {
  .framed-layout .gx-customizer {
    height: calc(100vh - 30px) !important;
  }
}
.gx-color-option {
  margin-bottom: 0;
  padding-left: 0;
  list-style: none;
}
.gx-color-option li {
  font-size: 36px;
  line-height: 1;
}
.gx-color-option li .gx-link {
  width: 40px;
  height: 40px;
  display: block;
  position: relative;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  overflow: hidden;
}
.gx-color-option li .gx-link:before {
  font-family: 'gaxon';
  font-size: 20px;
  content: '\30';
  position: absolute;
  left: 0;
  top: 0;
  z-index: 2;
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  display: none;
}
.gx-color-option li .gx-link.active:before {
  display: block;
}
.gx-color-option li .gx-link:after {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
  width: 20px;
  height: 40px;
  background-color: #9283d4;
}
.gx-color-option li .gx-link.gx-color-red:after {
  background-color: #ff2b7a;
}
.gx-color-option li .gx-link.gx-color-blue:after {
  background-color: #3da4e6;
}
.gx-color-option li .gx-link.gx-color-dark-blue:after {
  background-color: #0469b9;
}
.gx-color-option li .gx-link.gx-color-orange:after {
  background-color: #f18805;
}
.gx-color-option li .gx-link.gx-color-light-blue:after {
  background-color: #6a95ff;
}
.gx-color-option li .gx-link.gx-color-deep-orange:after {
  background-color: #f87060;
}
.gx-color-option li .gx-link.gx-color-light-purple1:after {
  background-color: #a172e7;
}
.gx-color-option li .gx-link.gx-color-light-purple2:after {
  background-color: #956fe7;
}
.gx-cus-customiz {
  padding-right: 20px;
}
.gx-layout-option li,
.gx-nav-option li {
  margin-bottom: 10px;
}
.gx-layout-option li span,
.gx-nav-option li span {
  position: relative;
  display: block;
}
.gx-layout-option li span:before,
.gx-nav-option li span:before {
  position: absolute;
  left: 50%;
  top: 50%;
  z-index: 1;
  font-family: 'gaxon' !important;
  content: '\30';
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  display: none;
  width: 20px;
  font-size: 12px;
  height: 20px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  background-color: #007aff;
  text-align: center;
  padding: 2px;
  color: #ffffff;
}
.gx-layout-option li span.active:before,
.gx-nav-option li span.active:before {
  display: block;
}
.layout .ant-divider .ant-divider-vertical {
  height: 20em !important;
}
.layout .login-border {
  border-left: 1px solid #dde1ee;
  height: 332px;
  display: flex;
  padding: 0 24px;
}
/*Login Styles*/
.gx-login-container {
  position: relative;
  height: 100%;
  padding-bottom: 30px;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-align-items: center;
  -ms-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
}
.gx-login-content {
  width: 100%;
  margin: auto;
  font-size: 14px;
}
.gx-login-content .ant-input {
  background-color: #f5f5f5;
  height: auto;
  padding: 6px 12px;
}
.gx-login-content .ant-input:focus {
  box-shadow: none;
  border-color: #007aff;
}
.gx-login-content .ant-input-affix-wrapper {
  background-color: #f5f5f5;
  max-height: none;
}
.gx-login-content .ant-form-item-control-wrapper {
  width: 100%;
}
.gx-login-content .ant-form-item-children {
  display: block;
}
@media screen and (max-width: 575px) {
  .gx-login-content {
    padding: 20px 20px 10px;
  }
}
.gx-app-login-wrap {
  height: 100%;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
  overflow-x: hidden;
}
@media screen and (max-width: 575px) {
  .gx-app-login-wrap {
    padding-top: 20px;
    -webkit-justify-content: flex-start;
    -ms-justify-content: flex-start;
    justify-content: flex-start;
  }
}
.gx-app-login-container {
  position: relative;
  max-width: 680px;
  width: 94%;
  margin: 0 auto;
}
@media screen and (max-width: 575px) {
  .gx-app-login-container {
    padding-bottom: 20px;
  }
}
.gx-app-login-container .gx-loader-view {
  position: absolute;
  left: 0;
  right: 0;
  text-align: center;
  top: 0;
  bottom: 0;
  z-index: 2;
}
.gx-app-login-main-content {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  background-color: #ffffff;
  -webkit-box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.03);
  -moz-box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.03);
  box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.03);
  -webkit-border-radius: 12px;
  -moz-border-radius: 12px;
  border-radius: 12px;
  font-size: 14px;
  overflow: hidden;
}
.gx-app-login-content {
  padding: 35px 35px 20px;
  width: 60%;
}
.gx-app-login-content .ant-input {
  background-color: #f5f5f5;
}
.gx-app-login-content .ant-input:focus {
  box-shadow: none;
  border-color: #007aff;
}
.gx-app-login-content .gx-btn {
  padding: 6px 35px !important;
  height: auto;
}
.gx-app-login-content .ant-form-item-control-wrapper {
  width: 100%;
}
.gx-app-login-content .ant-form-item-children {
  display: block;
}
@media screen and (max-width: 575px) {
  .gx-app-login-content {
    width: 100%;
    padding: 20px 20px 10px;
  }
}
.gx-app-login-header {
  margin-bottom: 30px;
}
@media screen and (max-width: 575px) {
  .gx-app-login-header {
    margin-bottom: 15px;
  }
}
.gx-app-logo-content {
  color: #ffffff;
  padding: 35px 35px 20px;
  width: 40%;
  position: relative;
  overflow: hidden;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
}
.gx-app-logo-content > * {
  position: relative;
  z-index: 2;
}
.gx-app-logo-content h1 {
  color: #ffffff;
}
@media screen and (max-width: 575px) {
  .gx-app-logo-content {
    width: 100%;
    padding: 20px 20px 10px;
  }
}
.gx-app-logo-content-bg {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}
.gx-app-logo-content-bg:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 122, 255, 0.7);
}
.gx-app-logo-content-bg img {
  width: 100%;
  height: 100%;
}
.gx-app-logo-wid {
  margin-bottom: auto;
}
.gx-app-social-block {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-align-items: center;
  -ms-align-items: center;
  align-items: center;
  -webkit-justify-content: space-between;
  -ms-justify-content: space-between;
  justify-content: space-between;
}
.gx-app-social-block .gx-social-link,
.gx-app-social-block .gx-social-link li {
  margin: 0;
}
.gx-app-social-block .gx-social-link span {
  border: solid 1px #007aff;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  color: #007aff;
}
.gx-app-social-block .gx-social-link span:hover,
.gx-app-social-block .gx-social-link span:focus {
  color: #ffffff;
  background-color: #007aff;
}
.gx-app-social-block p {
  margin-bottom: 0;
}
.gx-signin-form .ant-form-item-control-input {
  min-height: 40px;
}
#root {
  height: 100vh;
}
body {
  color: #585e75;
  font-size: 14px;
  font-family: 'Inter';
  font-variant: tabular-nums;
  line-height: 1.3;
  font-feature-settings: 'tnum', 'tnum';
}
body .ant-app {
  color: #585e75;
  font-size: 14px;
  font-family: 'Inter';
  font-variant: tabular-nums;
  line-height: 1.3;
  font-feature-settings: 'tnum', 'tnum';
}
.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 0.5em;
  color: #262626;
  font-weight: 400;
  line-height: 1.2;
}
img {
  vertical-align: middle;
}
.header-notification {
  display: flex;
  align-items: center;
}
.header-notification > .notification-bell {
  font-size: 20px;
  cursor: pointer;
}
.gx-sidebar-content > .ant-menu > .ant-menu-item {
  margin: 12px 0;
}
.gx-sidebar-content > .ant-menu > .ant-menu-item > .menu-icons {
  font-size: 20px;
}
.gx-sidebar-content > .ant-menu > .ant-menu-item > span {
  font-size: 18px;
}
.ant-table .ant-table-tbody .anticon {
  font-size: 20px;
  padding: 0 4px;
}
.ant-table .ant-table-tbody .ant-btn {
  margin: 0;
  padding: 0;
  height: auto;
}
.event-header-image .ant-upload-select-picture-card {
  width: 100%;
}
.event-header-image.ant-upload-picture-card-wrapper {
  padding: 0 16px;
}
.event-form .ant-form-item-label {
  padding: 0;
  margin: 0;
}
.ant-message-custom-content {
  display: flex;
  align-items: center;
}
.ant-message-custom-content > .ant-message-custom-content .ant-message-info {
  height: 36px;
  display: flex;
  align-items: center;
}
.icon-btn {
  display: flex;
  align-items: center;
}
.upload-image-container .ant-upload-select-picture-card {
  width: 100%;
}
.upload-image-container.ant-upload-picture-card-wrapper {
  padding: 0 16px;
}
.custom-icons-list {
  margin-right: 10px;
}
.ant-table-column-sorters {
  padding: 0;
}
.login-content {
  padding: 35px;
  width: 100%;
}
.ant-input-prefix {
  display: flex;
  align-items: center;
  justify-content: center;
}
.reset-password {
  text-align: center;
}
.ant-btn-sm {
  line-height: 0;
}
.attendee-details .ant-form-item {
  min-width: 215px;
  margin-right: 16px;
}
.rsvp-modal .ant-modal-footer div {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.ant-input-suffix {
  display: flex;
  align-items: center;
}
.option-input {
  border: unset;
  border-bottom: 1px solid #d9d9d9;
  border-radius: 0;
}
.option-input:focus {
  border: unset;
  border-bottom: 1px solid #d9d9d9;
  border-radius: 0;
  box-shadow: unset;
}
.option-input:hover {
  border-color: transparent;
  border-right-width: 0;
  border: unset;
  border-bottom: 1px solid #d9d9d9;
  border-radius: 0;
}
.option-input.ant-input-affix-wrapper-focused {
  box-shadow: unset;
}
.pointer {
  cursor: pointer;
}
.donation-list {
  overflow: auto;
}
.donation-card {
  border: 1px solid #e5e5e5;
  padding: 14px 32px;
  border-radius: 4px;
  margin-right: 24px;
  cursor: pointer;
  min-width: 200px;
  text-align: center;
  min-height: 100px;
  margin-bottom: 12px;
}
.donation-card.active {
  background-color: #efefef;
}
.ministry-modal {
  height: 500px;
}
.ministry-modal .ant-modal-body {
  padding: 0;
}
.ministry-modal object {
  min-height: 500px;
}
.h-full {
  height: 100%;
}
.w-full {
  width: 100%;
}
.dialog .ant-modal-footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.dialog .ant-modal-footer > button:first-child {
  margin-right: 0px;
}
.action-icons {
  display: flex;
  align-items: center;
}
.action-icons button:first-child {
  margin-right: 12px;
}
.action-icons > button {
  margin-bottom: 0;
}
.text-center {
  text-align: center;
}
.text-right {
  text-align: right;
}
.text-left {
  text-align: left;
}
.m-auto {
  margin: auto;
}
.m-0 {
  margin: 0;
}
.m-16 {
  margin: 16px;
}
.m-24 {
  margin: 24px;
}
.mt-6 {
  margin-top: 6px;
}
.mt-8 {
  margin-top: 8px;
}
.mt-10 {
  margin-top: 10px;
}
.mt-15 {
  margin-top: 15px;
}
.mt-16 {
  margin-top: 16px;
}
.mt-20 {
  margin-top: 20px;
}
.mt-24 {
  margin-top: 24px;
}
.mt-32 {
  margin-top: 32px;
}
.mt-35 {
  margin-top: 35px;
}
.mt-45 {
  margin-top: 45px;
}
.mt-56 {
  margin-top: 56px;
}
.mr-0 {
  margin-right: 0px;
}
.mr-4 {
  margin-right: 4px;
}
.mr-5 {
  margin-right: 5px;
}
.mr-6 {
  margin-right: 6px;
}
.mr-8 {
  margin-right: 8px;
}
.mr-10 {
  margin-right: 10px;
}
.mr-12 {
  margin-right: 12px;
}
.mr-15 {
  margin-right: 15px;
}
.mr-16 {
  margin-right: 16px;
}
.mr-18 {
  margin-right: 18px;
}
.mr-24 {
  margin-right: 24px;
}
.mr-25 {
  margin-right: 25px;
}
.ml-4 {
  margin-left: 4px;
}
.ml-6 {
  margin-left: 6px;
}
.ml-7 {
  margin-left: 7px;
}
.ml-8 {
  margin-left: 8px;
}
.ml-14 {
  margin-left: 14px;
}
.ml-15 {
  margin-left: 15px;
}
.ml-16 {
  margin-left: 16px;
}
.ml-24 {
  margin-left: 24px;
}
.mb-0 {
  margin-bottom: 0px;
}
.mb-4 {
  margin-bottom: 4px;
}
.mb-5 {
  margin-bottom: 5px;
}
.mb-8 {
  margin-bottom: 8px;
}
.mb-12 {
  margin-bottom: 12px;
}
.mb-16 {
  margin-bottom: 16px;
}
.mb-18 {
  margin-bottom: 18px;
}
.mb-20 {
  margin-bottom: 20px;
}
.mb-24 {
  margin-bottom: 24px;
}
.mb-40 {
  margin-bottom: 40px;
}
.p-0 {
  padding: 0;
}
.pr-0 {
  padding-right: 0;
}
.pr-5 {
  padding-right: 5px;
}
.pr-16 {
  padding-right: 16px;
}
.pl-16 {
  padding-left: 16px;
}
.pt-8 {
  padding-top: 8px;
}
.p-10 {
  padding: 10px;
}
.p-16 {
  padding: 16px;
}
.p-24 {
  padding: 24px;
}
.d-none {
  display: none;
}
.d-block {
  display: block;
}
.d-inline-block {
  display: inline-block;
}
.gap-8 {
  gap: 8px;
}
.gap-16 {
  gap: 16px;
}
.gap-20 {
  gap: 20px;
}
.gap-24 {
  gap: 24px;
}
.flex-1 {
  flex: 1;
}
.d-flex {
  display: flex;
}
.flex-horizontal {
  flex-direction: row;
}
.flex-vertical {
  flex-direction: column;
}
.justify-center {
  justify-content: center;
}
.justify-start {
  justify-content: flex-start;
}
.justify-end {
  justify-content: flex-end;
}
.justify-between {
  justify-content: space-between;
}
.justify-around {
  justify-content: space-around;
}
.align-center {
  align-items: center;
}
.align-start {
  align-items: flex-start;
}
.align-end {
  align-items: flex-end;
}
.flex-grow-1 {
  flex-grow: 1;
}
.flex-grow-2 {
  flex-grow: 2;
}
.flex-shrink-0 {
  flex-shrink: 0;
}
.flex-shrink-1 {
  flex-shrink: 1;
}
.flex-shrink-2 {
  flex-shrink: 2;
}
.flex-wrap {
  flex-wrap: wrap;
}
.align-self-end {
  align-self: flex-end;
}
.width-percent-5 {
  width: 5%;
}
.width-percent-10 {
  width: 10%;
}
.width-percent-20 {
  width: 20%;
}
.width-percent-25 {
  width: 25%;
}
.width-percent-36 {
  width: 36%;
}
.width-percent-40 {
  width: 40%;
}
.width-percent-45 {
  width: 45%;
}
.width-percent-47 {
  width: 47%;
}
.width-percent-48 {
  width: 48%;
}
.width-percent-50 {
  width: 50%;
}
.width-percent-55 {
  width: 55%;
}
.width-percent-60 {
  width: 60%;
}
.width-percent-70 {
  width: 70%;
}
.width-percent-80 {
  width: 80%;
}
.width-percent-90 {
  width: 90%;
}
.full-width {
  width: 100%;
}
.line-16 {
  line-height: 16px;
}
.line-20 {
  line-height: 20px;
}
.line-22 {
  line-height: 22px;
}
.line-24 {
  line-height: 24px;
}
.line-26 {
  line-height: 26px;
}
.line-30 {
  line-height: 30px;
}
.line-36 {
  line-height: 36px;
}
.line-45 {
  line-height: 45px;
}
.line-49 {
  line-height: 49px;
}
.h-16 {
  height: 16px;
}
.h-30 {
  height: 30px;
}
.h-36 {
  height: 36px;
}
.h-38 {
  height: 38px;
}
.h-40 {
  height: 40px;
}
.h-64 {
  height: 64px;
}
.h-130 {
  height: 130px;
}
.h-150 {
  height: 150px;
}
.h-180 {
  height: 180px;
}
.h-182 {
  height: 182px;
}
.h-188 {
  height: 188px;
}
.h-192 {
  height: 192px;
}
.h-156 {
  height: 156px;
}
.h-158 {
  height: 158px;
}
.h-300 {
  height: 300px;
}
.h-75vh {
  height: 70vh;
}
.h-100vh {
  height: 100vh;
}
.pointer {
  cursor: pointer;
}
.b-0 {
  border: none;
}
.b-8 {
  border-radius: 8px;
}
.br-50 {
  border-radius: 50px;
}
.font-xx-large {
  font-size: xx-large;
}
.font-large {
  font-size: large;
}
.font-24 {
  font-size: 24px;
}
.font-20 {
  font-size: 20px;
}
.font-18 {
  font-size: 18px;
}
.font-14 {
  font-size: 14px;
}
.font-16 {
  font-size: 16px;
}
.font-12 {
  font-size: 12px;
}
.font-10 {
  font-size: 10px;
}
.font-500 {
  font-weight: 500;
}
.font-600 {
  font-weight: 600;
}
.word-break-word {
  word-break: break-word;
}
.ant-input::placeholder {
  color: #a4aabe;
}
.ant-select-selection-placeholder {
  color: #a4aabe;
}
.ant-input {
  min-height: 38px;
}
.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  min-height: 38px;
}
.ant-select-multiple .ant-select-selector {
  min-height: 38px;
}
.ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  color: #1a1d40;
  background-color: #f2f4f7;
  border-color: #dde1ee;
  box-shadow: none;
  cursor: not-allowed;
  opacity: 1;
}
.ant-select-disabled.ant-select-multiple .ant-select-selection-item {
  color: #1a1d40;
}
.ant-input-affix-wrapper-disabled {
  background-color: #f2f4f7;
  border-color: #dde1ee;
}
.ant-input[disabled] {
  color: #1a1d40;
  background-color: #f2f4f7;
  border-color: #dde1ee;
  box-shadow: none;
  cursor: not-allowed;
  opacity: 1;
}
.ant-select-multiple.ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  background-color: #f2f4f7;
}
.ant-modal-content {
  overflow: hidden;
}
.gx-main-content-wrapper .tag-bg {
  width: 48px;
  font-size: 10px;
  height: 18px;
  background: #f9deaf;
  color: #97650d;
  border: none;
  margin-bottom: 4px;
}
.gx-main-content-wrapper .tag-bg:hover {
  background-color: #f9deaf;
}
.tag-bg {
  width: 48px;
  font-size: 10px;
  height: 18px;
  background: #f9deaf;
  color: #97650d;
  border: none;
  margin-bottom: 4px;
}
.save-btn {
  font-weight: 500;
  width: 96px;
}
.cancel-btn {
  width: 96px;
  font-weight: 500;
  background-color: #f2f4f7;
  border: none;
  margin-right: 16px;
}
.delete-btn {
  width: 96px;
  font-weight: 500;
  border: none;
}
.no-access-height {
  height: calc(100vh - 57px);
}
.no-access-height .ant-card-body {
  height: inherit;
  display: flex;
  justify-content: center;
  align-items: center;
}
.html-editor .ql-toolbar.ql-snow {
  border-radius: 10px 10px 0 0;
  background-color: #fafafa;
}
.html-editor .ql-toolbar.ql-snow Button {
  margin: 0;
}
.html-editor .ql-container.ql-snow {
  border-radius: 0 0 10px 10px;
}
.html-editor .ql-container.ql-snow .ql-editor {
  height: 15em;
  overflow: auto;
}
.router-prompt .ant-modal-content .ant-modal-body {
  padding: 20px;
}
.common-footer.ant-layout-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: unset;
  padding: 14px 32px;
  color: #585e75;
  font-size: 14px;
  background: #fffffe;
}
.common-footer.ant-layout-footer .footer-image {
  margin-left: 8px;
}
@media screen and (max-width: 768px) {
  .common-footer.ant-layout-footer {
    display: flex;
    flex-direction: column;
  }
}
.custom-popconfirm .ant-popover-inner-content {
  width: max-content;
}
.custom-scrollbar::-webkit-scrollbar-track,
.ant-table-body::-webkit-scrollbar-track {
  background: #ffffff;
}
.custom-scrollbar::-webkit-scrollbar,
.ant-table-body::-webkit-scrollbar {
  width: 5px;
}
.custom-scrollbar::-webkit-scrollbar-thumb,
.ant-table-body::-webkit-scrollbar-thumb {
  background-color: #dde1ee;
  border-radius: 20px;
}
.ant-modal.ant-modal-confirm .ant-modal-content {
  padding: 20px 24px;
}
.ant-modal .ant-modal-content {
  padding: 0;
}
.ant-modal .ant-modal-content .ant-modal-header {
  border-bottom: 1px solid #e8e8e8;
  margin-bottom: 0;
}
.ant-modal .ant-modal-content .ant-modal-footer {
  margin-top: 0;
  padding: 10px 16px;
  border-top: 1px solid #e8e8e8;
}
fieldset {
  min-width: 0;
  margin: 0;
  padding: 0;
  border: 0;
}
.custom-smart-banner {
  background-color: #f3f3f3;
  padding: 10px 16px;
}
.custom-smart-banner .smart-banner-info {
  font-size: 12px;
  line-height: 16px;
}
.upgrade-button.ant-btn-primary {
  padding: 8px 15px;
  background: #1a1d40;
  display: inline-flex;
  align-items: center;
  color: #ffffff;
}
.upgrade-button.ant-btn-primary span {
  margin-left: 8px;
  line-height: 1.3;
  font-weight: 500;
}
.upgrade-button.ant-btn-primary:not(:disabled):not(.ant-btn-disabled):hover {
  background: #1a1d40bf;
}
.upgrade-button.ant-btn-sm {
  padding: 3px 6px;
  border-radius: 20px;
  display: flex;
  align-items: center;
}
.upgrade-button.ant-btn-sm svg {
  height: 12px;
  width: 12px;
  margin-left: 3px;
}
.upgrade-button.ant-btn-sm svg.no-margin {
  margin-left: 0;
}
.upgrade-button.ant-btn-sm span {
  font-size: 12px;
  margin: 0;
}
.add-ons-upgrade-button {
  background-color: #f2f4f7;
  border: none;
}
.add-ons-upgrade-button span {
  font-weight: 500;
}
.fade-feature {
  opacity: 0.5;
  pointer-events: none;
  user-select: none;
}
.select-display-flex-team-member-pro.ant-select-item .ant-select-item-option-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.select-display-flex-team-member-pro .ant-select-selector .ant-select-selection-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.ant-btn.default {
  color: #007aff;
  border-color: #007aff;
}
.payment-banner {
  height: 36px;
  background: #1a1d40;
  text-align: center;
}
.payment-banner p {
  color: #ffffff;
  font-size: 16px;
  font-weight: 400;
  line-height: 36px;
  margin: auto;
}
.current-plan-details .infinite-icon {
  margin-left: 4px;
}
.current-plan-details .ant-card {
  height: 100%;
  display: flex;
  flex-direction: column;
}
.current-plan-details .ant-card .ant-card-head {
  padding: 16px;
  background-color: #f2f4f7;
}
.current-plan-details .ant-card .ant-card-head .ant-card-head-wrapper .ant-card-head-title {
  color: #1a1d40;
  line-height: 24px;
}
.current-plan-details .ant-card .ant-card-body {
  padding: 16px;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.current-plan-details .ant-card .ant-card-body .blue-text {
  color: #007aff;
  font-size: 24px;
  line-height: 24px;
  font-weight: 500;
  font-style: normal;
}
.current-plan-details .account-usage .ant-card-body ul {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.current-plan-details .account-usage .ant-card-body ul li {
  width: 48%;
  display: flex;
  justify-content: space-between;
  font-weight: 500;
  font-size: 16px;
}
.current-plan-details .account-usage .ant-card-body ul li:nth-child(1),
.current-plan-details .account-usage .ant-card-body ul li:nth-child(2) {
  margin-bottom: 16px;
  padding-bottom: 16px;
  border-bottom: 1px solid #dde1ee;
}
.current-plan-details .account-usage .ant-card-body ul li .usage {
  width: 46%;
  text-align: right;
}
.current-plan-details .account-usage .ant-card-body ul li .usage .ant-progress {
  max-width: 200px;
  margin-left: auto;
}
.current-plan-details .account-usage .ant-card-body ul li .usage .ant-progress.ant-progress-status-success .ant-progress-text {
  color: #f5222d;
}
.current-plan-details .account-usage .ant-card-body ul li .usage .ant-progress .ant-progress-text {
  width: auto;
}
.payment-modal-content {
  max-width: 448px;
  margin-top: 58px;
  margin-bottom: 24px;
}
.payment-modal-content h2 {
  font-size: 24px;
  color: #1a1d40;
  font-weight: 500;
}
.payment-modal-content p {
  font-size: 16px;
}
.status-page-wrapper {
  max-width: 1184px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: auto;
  height: calc(100vh - 56px);
  width: 100%;
  text-align: center;
}
.status-page-wrapper .error-code {
  font-size: 44px;
  font-weight: 600;
  margin: 0;
}
.status-page-wrapper .access-forbiden {
  font-size: 24px;
  font-weight: 400;
  margin: 0;
}
.status-page-wrapper .description {
  font-size: 16px;
}
.status-page-wrapper .divider {
  width: 182px;
  margin-block: 40px;
  background-color: #007aff;
  height: 4px;
  border-radius: 4px;
}
.status-page-wrapper .go-back-btn {
  width: 224px;
}
.gx-page-error-container {
  height: calc(100% - 60px);
  display: flex;
  align-items: center;
  justify-content: center;
}
.dynamic-link-filter-date {
  padding: 4px;
}
.new-feature-modal .upgrade-button span {
  margin: 0;
}
.tester-access .ant-modal .ant-modal-content .ant-modal-body {
  padding: 24px;
}
.tester-access .ant-modal .ant-modal-content .ant-modal-footer {
  display: none;
}
